import React, { useState, useRef, useEffect, useContext } from "react";
import Setting from "./Setting";
import OffcanvasComponent from "./OffcanvasComponent";
import SwapComponent from "./SwapComponent";
import Context from "../Context/Context";

const SwapPage = () => {
  const { customValue } = useContext(Context);
  const settingRef = useRef(null);
  const [isOpen3, setIsOpen3] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
  };
  const handleConnectWallet = () => {
    setShowOffcanvas(true);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingRef.current && !settingRef.current.contains(event.target)) {
        setIsOpen3(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingRef]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="text-light fs-4">Swap</span>
        <div
          ref={settingRef}
          className={`d-flex align-items-center justify-content-center ${
            customValue < 0.05 || customValue == 0 || customValue > 1
              ? "goRgmL"
              : "bIBvpU"
          } bncztk`}
        >
          {customValue !== " " && (
            <div>
              {customValue}
              <span className="me-1">%</span>
              <span className="me-2">slippage</span>
            </div>
          )}
          <img
            src="/images/settings.png"
            style={{ height: "20px", width: "20px", cursor: "pointer" }}
            onClick={toggleDropdown3}
            alt=""
          />
          {isOpen3 && <Setting />}
        </div>
      </div>
      <div id="swap-page" className="mt-3">
        <div className="enpFfZ ">
          <SwapComponent />
        </div>
      </div>
    </div>
  );
};

export default SwapPage;
