import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import OffcanvasComponent from '../Dropdown/OffcanvasComponent';
import Logo from '../Assets/images2/trenddx-fav-inner.png';
import '../Assets/CSS/offcanvas.css';
import NavDropdown from '../Dropdown/NavDropdown';
import NetworkOverlay from '../Dropdown/NetworkOverlay';
import ConnectButton from '../ContractAction/Wallet/ConnectWallet';
import SearchBar from '../Dropdown/Searchbar';

const MyNavbar = ({ selectedNetwork, onSelectNetwork }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const imageRef = useRef(null);
  const networkOverlayRef = useRef(null);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen1(false);
        setIsDropdownOpen(false);
      }
      if (networkOverlayRef.current && !networkOverlayRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isOpen1, isDropdownOpen]);

  return (
    <div className='header_wrapper sticky-top'>
      <Navbar expand='lg' className='px-5' style={{ gap: '5px' }}>
        <Navbar.Brand as={Link} to='/' style={{marginLeft:"1rem"}}>
          <img src={Logo} height='36px' width='36px' alt='' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' className='bg-light' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <NavLink to='/swap' className='nav-link ms-2' activeClassName='active'>
              Swap
            </NavLink>
            <NavLink to='/explore' className='nav-link ms-2' activeClassName='active'>
              Explore
            </NavLink>
            <NavLink to='/analytics' className='nav-link ms-2' activeClassName='active'>
              Analytics
            </NavLink>
            <NavLink to='/pool' className='nav-link ms-2' activeClassName='active'>
              Pool
            </NavLink>
            <div ref={dropdownRef}>
              <Link to='#' className='nav-link ms-2' onClick={toggleDropdown1}>
                <img src='/images/dropdown.png' ref={imageRef} className={`icons image ${isDropdownOpen ? 'rotated' : ''}`} alt='' />
              </Link>
              {isOpen1 &&
                <NavDropdown />}
            </div>
          </Nav>
          <SearchBar />
        </Navbar.Collapse>
        <div className='d-flex align-items-center'>
          <NetworkOverlay selectedNetwork={selectedNetwork} onSelectNetwork={onSelectNetwork} />
          <ConnectButton />
        </div>
      </Navbar>
      <OffcanvasComponent show={showOffcanvas} onHide={handleCloseOffcanvas} />
    </div>
  );

};

export default MyNavbar;