import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from 'lodash';
import MyNavbar from "../Component/MyNavbar";
import plus from "../Assets/images2/tdesign_swap.png";
import pharrow from "../Assets/images2/ph_arrow-down.png";
import phquestion from "../Assets/images2/ph_question.png";
import cross from '../Assets/images2/radix-icons_cross-2.svg';
import PlusComponent from "../Dropdown/PlusComponent";
import SelectToken2 from "../Dropdown/SelectToken2";
import Context from "../Context/Context";
import ImportModalContent from '../Dropdown/ImportModalContent';
import { addliquidity, getCurrentChainIdRouterAddress, getCurrentChainIdTokenAddress, getTokenBalance, getValueOutTokenAtoTokenB, getTokenContractAddressExists, getTokenSymbol, setTokenApproveRouter, getAllowanceTokentoRouter, getCurrentChainIdNativeTokenAddress, addLiquidityETH, getLivePricingValue, getPairforTokenATokenB, getUserLiquidityDetails, getDefaultNativeTokenAddress, getUserPairBalance, getPairTotalSupplyAndPoolSharePerc, setPairApproveRouter, removeliquidity, getPairAllowanceTokentoRouter } from '../ContractAction/TrendswapAction';
import { getCurrentChainId, getCurrentTxExplorer } from "../ContractAction/ContractDependency";

const AddLiquidty = (props) => {

  const { selectedNetwork, setSelectedNetwork, liquidityselectedToken1Address, liquidityselectedToken2Address, showNestedModal, closeNestedModal, } = useContext(Context);
  const [inputValue, setInputValue] = useState(null);
  const [selectedTokenImage1, setSelectedTokenImage1] = useState('');
  const [selectedTokenImage2, setSelectedTokenImage2] = useState('');
  const [selectedTokenTicker1, setSelectedTokenTicker1] = useState('');
  const [selectedTokenTicker2, setSelectedTokenTicker2] = useState('');
  const [selectedToken1Addr, setSelectedToken1Addr] = useState('');
  const [selectedToken2Addr, setSelectedToken2Addr] = useState('');
  const [symbolSelectedToken1Addr, setSymbolSelectedToken1Addr] = useState('');
  const [symbolSelectedToken2Addr, setSymbolSelectedToken2Addr] = useState('');
  const [amountA, setAmountA] = useState('');
  const [amountB, setAmountB] = useState('');
  const [accesscomponents, setAccessComponents] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [isAmountAEntered, setIsAmountAEntered] = useState(false);
  const [isAmountBEntered, setIsAmountBEntered] = useState(false);
  const [tokenApprove, setTokenApprove] = useState(false);
  const [userTokenALiquidityAdded, setUserTokenALiquidityAdded] = useState(0);
  const [userTokenBLiquidityAdded, setUserTokenBLiquidityAdded] = useState(0);
  const [userPairBalance, setUserPairBalance] = useState(0);
  const [userPoolSharePerc, setUserPoolSharePerc] = useState(0);
  const [userAddingLiqPoolSharePerc, setUserAddingLiqPoolSharePerc] = useState(0);
  const [TokenABalance, setTokenABalance] = useState(0);
  const [TokenBBalance, setTokenBBalance] = useState(0);
  const [tokenAAllowance, setTokenAAllowance] = useState(0);
  const [tokenBAllowance, setTokenBAllowance] = useState(0);
  const [tokenAApprove, setTokenAApprove] = useState(0);
  const [tokenBApprove, setTokenBApprove] = useState(0);
  const [pricenpoolAtoB, setPricenpoolAtoB] = useState(0);
  const [pricenpoolBtoA, setPricenpoolBtoA] = useState(0);
  const [isWalletConnected, setIsWalletConnected] = useState(localStorage.getItem('connectedAccount') || "");
  const handleSelectNetwork = (network) => setSelectedNetwork(network);

  console.log("liquidityselectedToken1Address_liquidityselectedToken2Address ", liquidityselectedToken1Address, liquidityselectedToken2Address)
  console.log(props.selectedTokenImage)

  const isTokenSelectedForButton = (buttonId) => {
    try {
      return buttonId === 1
        ? !!selectedTokenImage1 && !!selectedTokenTicker1
        : buttonId === 2
          ? !!selectedTokenImage2 && !!selectedTokenTicker2
          : (console.error(`Invalid buttonId for token selection: ${buttonId}`), false);
    } catch (error) {
      console.error(`Error in isTokenSelectedForButton: ${error.message}`);
      return false;
    }
  };
  const buttonStyle = { backgroundColor: isTokenSelectedForButton(1) ? 'rgba(5, 44, 76, 1)' : '', };
  const button2Style = { backgroundColor: isTokenSelectedForButton(2) ? 'rgba(5, 44, 76, 1)' : '', };

  const openModal = (buttonId) => {
    try {
      buttonId === 1 ? setShowModal1(true)
        : buttonId === 2 ? setShowModal2(true)
          : console.error(`Invalid buttonId for opening modal: ${buttonId}`);
    } catch (error) {
      console.error(`Error in openModal: ${error.message}`);
    }
  };

  const closeModal = (buttonId) => {
    try {
      buttonId === 1 ? setShowModal1(false)
        : buttonId === 2 ? setShowModal2(false)
          : console.error(`Invalid buttonId for closing modal: ${buttonId}`);
    } catch (error) {
      console.error(`Error in closeModal: ${error.message}`);
    }
  };

  const handleTokenSelect = (image, ticker, buttonId) => {
    try {
      if (buttonId === 1) {
        if (selectedTokenImage2 === image && selectedTokenTicker2 === ticker) {
          console.log("Cannot select the same token as button 2.");
          return;
        }
        if (selectedTokenImage1 === image) {
          setSelectedTokenImage1('');
          setSelectedTokenTicker1('');
        } else {
          setSelectedTokenImage1(image);
          setSelectedTokenTicker1(ticker);
          console.log("Token A is selected:", liquidityselectedToken1Address);
        }
        closeModal(1);
      } else if (buttonId === 2) {
        if (selectedTokenImage1 === image && selectedTokenTicker1 === ticker) {
          console.log("Cannot select the same token as button 1.");
          return;
        }
        if (selectedTokenImage2 === image) {
          setSelectedTokenImage2('');
          setSelectedTokenTicker2('');
        } else {
          setSelectedTokenImage2(image);
          setSelectedTokenTicker2(ticker);
          console.log("Token B is selected:", liquidityselectedToken2Address);
        }
        closeModal(2);
      } else {
        console.error('Invalid buttonId:', buttonId);
      }
    } catch (error) {
      console.error('An error occurred while selecting the token:', error);
    }
  };

  const handleMaxClick = (token) => {
    try {
      if (token === 'A') {
        setAmountA(TokenABalance.toString());
        setIsAmountAEntered(true);
      } else if (token === 'B') {
        setAmountB(TokenBBalance.toString());
        setIsAmountBEntered(true);
      } else {
        console.error('Invalid token:', token);
      }
    } catch (error) {
      console.error('An error occurred while handling max click:', error);
    }
  };

  const handleInputAmountA = async (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^0-9.]/g, '');
    setAmountA(numericValue);
    setIsAmountAEntered(true);
    if (!event.target.dataset.handleChangeB) {
      try {
        const [tokenAContractAddr, tokenBContractAddr] = await Promise.all([
          getCurrentChainIdTokenAddress(selectedTokenTicker1),
          getCurrentChainIdTokenAddress(selectedTokenTicker2),
        ]);
        const tokenAAddr = tokenAContractAddr || selectedToken1Addr;
        const tokenBAddr = tokenBContractAddr || selectedToken2Addr;
        const calculatedAmountB = await getValueOutTokenAtoTokenB(numericValue, tokenAAddr, tokenBAddr);
        setAmountB(calculatedAmountB > 0 ? calculatedAmountB : amountB);
      } catch (error) {
        console.error("Error calculating amount B:", error);
        setAmountB(amountB);
      }
    }
    try {
      const [totalPairBalance] = await Promise.all([
        getPairTotalSupplyAndPoolSharePerc(selectedToken1Addr, selectedToken2Addr),
      ]);
      const newValue = parseFloat(numericValue);
      console.log('totalPairBalance', totalPairBalance[0], newValue);
      const newAddValuePerc = totalPairBalance[0] !== 0 ?
        Math.min((newValue * 10 ** 18 / totalPairBalance[0]) * 100, 100) :
        0;
      console.log('newAddValuePercAddLiquidity', newAddValuePerc);
      setUserAddingLiqPoolSharePerc(Math.max(newAddValuePerc || 0, 0));
    } catch (error) {
      console.error('Error calculating liquidity percentage:', error);
      setUserAddingLiqPoolSharePerc(0);
    }
  };

  const handleInputAmountB = async (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/[^0-9.]/g, '');
    setAmountB(numericValue);
    setIsAmountBEntered(true);
    event.target.dataset.handleChangeB = true;
    try {
      const [tokenAContractAddr, tokenBContractAddr] = await Promise.all([
        getCurrentChainIdTokenAddress(selectedTokenTicker1),
        getCurrentChainIdTokenAddress(selectedTokenTicker2),
      ]);
      const tokenAAddr = tokenAContractAddr || selectedToken1Addr;
      const tokenBAddr = tokenBContractAddr || selectedToken2Addr;
      const calculatedAmountA = await getValueOutTokenAtoTokenB(numericValue, tokenBAddr, tokenAAddr);
      setAmountA(calculatedAmountA > 0 ? calculatedAmountA : amountA);
    } catch (error) {
      console.error("Error calculating amount A:", error);
      setAmountA(amountA);
    }
  };

  const handleApproveToken = async (amount, tokenContractAddr) => {
    try {
      const tokenSymbol = await getTokenSymbol(tokenContractAddr);
      if (tokenSymbol) {
        await setTokenApproveRouter(amount, tokenContractAddr);
        setTokenApprove((prev) => !prev);
      } else {
        console.warn("Token symbol not found for address:", tokenContractAddr);
      }
    } catch (error) {
      console.error("Error approving token:", error);
    }
  };

  const handleAddLiquidity = async () => {
    try {
      const chainId = await getCurrentChainId();
      const [tokenAContractAddr, tokenBContractAddr] = await Promise.all([
        getCurrentChainIdTokenAddress(selectedTokenTicker1),
        getCurrentChainIdTokenAddress(selectedTokenTicker2),
      ]);
      const tokenAAddr = tokenAContractAddr || selectedToken1Addr;
      const tokenBAddr = tokenBContractAddr || selectedToken2Addr;
      const [tokenASymbol, tokenBSymbol] = await Promise.all([
        getTokenSymbol(tokenAAddr),
        getTokenSymbol(tokenBAddr),
      ]);
      if (tokenASymbol && tokenBSymbol) {
        const [isTokenANative, isTokenBNative] = await Promise.all([
          getCurrentChainIdNativeTokenAddress(tokenAAddr),
          getCurrentChainIdNativeTokenAddress(tokenBAddr),
        ]);
        let txhash;
        if (isTokenANative || isTokenBNative) {
          console.log("One or both tokens are native");
          txhash = await addLiquidityETH(amountA, amountB, tokenAAddr, tokenBAddr);
        } else {
          console.log("Neither token is native");
          txhash = await addliquidity(amountA, amountB, tokenAAddr, tokenBAddr);
        }
        if (txhash) {
          console.log("Transaction hash:", txhash);
          handleTxhashShow(txhash);
        }
      } else {
        console.warn("No token symbols found");
      }
    } catch (error) {
      console.error("Error adding liquidity:", error);
    }
  };

  const handleTxhashShow = async (transactionHash) => {
    try {
      const txExplorer = await getCurrentTxExplorer();
      toast.success(
        <div>
          Transaction Receipt: <br />
          <a href={`${txExplorer}${transactionHash}`} target="_blank" rel="noopener noreferrer">
            View on Block Explorer
          </a>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setAmountA('');
      setAmountB('');
    } catch (error) {
      console.error("Error displaying transaction hash:", error);
    }
  };


  const checkWalletConnection = useCallback(debounce(() => {
    try {
      const storedAccount = localStorage.getItem('connectedAccount');
      setIsWalletConnected(storedAccount || "");
    } catch (error) {
      console.error('Error checking wallet connection:', error);
    }
  }, 300), [setIsWalletConnected]);

  const fetchLiquidityAddData = useCallback(debounce(async () => {
    if (!liquidityselectedToken1Address || !liquidityselectedToken2Address) {
      console.warn("Invalid token addresses:", liquidityselectedToken1Address, liquidityselectedToken2Address);
      return;
    }
    setSelectedToken1Addr(liquidityselectedToken1Address);
    setSelectedToken2Addr(liquidityselectedToken2Address);
    console.log("Selected Token Addresses:", liquidityselectedToken1Address, liquidityselectedToken2Address);
    try {
      const [userTokenALiquidityStored, userTokenBLiquidityStored] = await Promise.all([
        getUserLiquidityDetails(liquidityselectedToken1Address, liquidityselectedToken2Address, liquidityselectedToken1Address),
        getUserLiquidityDetails(liquidityselectedToken1Address, liquidityselectedToken2Address, liquidityselectedToken2Address)
      ]);
      setUserTokenALiquidityAdded(userTokenALiquidityStored || 0);
      setUserTokenBLiquidityAdded(userTokenBLiquidityStored || 0);
    } catch (error) {
      console.error("Error fetching user token liquidity:", error);
      setUserTokenALiquidityAdded(0);
      setUserTokenBLiquidityAdded(0);
    }
    try {
      const [symbolSelectedToken1Addr, symbolSelectedToken2Addr] = await Promise.all([
        getTokenSymbol(liquidityselectedToken1Address),
        getTokenSymbol(liquidityselectedToken2Address)
      ]);
      setSymbolSelectedToken1Addr(symbolSelectedToken1Addr || '');
      setSymbolSelectedToken2Addr(symbolSelectedToken2Addr || '');
      console.log("Token Symbols:", symbolSelectedToken1Addr, symbolSelectedToken2Addr);
    } catch (error) {
      console.error("Error fetching token symbols:", error);
      setSymbolSelectedToken1Addr('');
      setSymbolSelectedToken2Addr('');
    }
  }, 300), [liquidityselectedToken1Address, liquidityselectedToken2Address]);

  const fetchAllowanceTokentoRouter = useCallback(debounce(async () => {
    try {
      const chainId = await getCurrentChainId();
      const [tokenAContractAddr, tokenBContractAddr] = await Promise.all([
        getCurrentChainIdTokenAddress(selectedTokenTicker1),
        getCurrentChainIdTokenAddress(selectedTokenTicker2)
      ]);
      const tokenaContractAddr = tokenAContractAddr || selectedToken1Addr;
      const tokenbContractAddr = tokenBContractAddr || selectedToken2Addr;
      if (!tokenaContractAddr || !tokenbContractAddr) {
        console.warn("Invalid token addresses:", tokenaContractAddr, tokenbContractAddr);
        return;
      }
      const [allowanceTokenA, allowanceTokenB] = await Promise.all([
        getAllowanceTokentoRouter(tokenaContractAddr),
        getAllowanceTokentoRouter(tokenbContractAddr)
      ]);
      setTokenAAllowance(allowanceTokenA > 0 ? allowanceTokenA : 0);
      setTokenBAllowance(allowanceTokenB > 0 ? allowanceTokenB : 0);
      console.log("AddLiquidity_allowanceA", allowanceTokenA, "allowanceB", allowanceTokenB);
    } catch (error) {
      console.error("Error fetching allowances:", error);
      setTokenAAllowance(0);
      setTokenBAllowance(0);
    }
  }, 300), [amountA, amountB, tokenApprove]);

  const fetchBalance = useCallback(debounce(async () => {
    try {
      const chainId = await getCurrentChainId();
      const [tokenAContractAddr, tokenBContractAddr] = await Promise.all([
        getCurrentChainIdTokenAddress(selectedTokenTicker1),
        getCurrentChainIdTokenAddress(selectedTokenTicker2)
      ]);
      const tokenaContractAddr = tokenAContractAddr || selectedToken1Addr;
      const tokenbContractAddr = tokenBContractAddr || selectedToken2Addr;
      if (!tokenaContractAddr || !tokenbContractAddr) {
        console.warn("Invalid token addresses:", tokenaContractAddr, tokenbContractAddr);
        return;
      }
      const [balanceA, balanceB] = await Promise.all([
        getTokenBalance(tokenaContractAddr),
        getTokenBalance(tokenbContractAddr)
      ]);
      setTokenABalance(balanceA > 0 ? balanceA : 0);
      setTokenBBalance(balanceB > 0 ? balanceB : 0);
      console.log("AddLiquidity_balanceA", TokenABalance, "balanceB", TokenBBalance);
    } catch (error) {
      console.error("Error fetching balances:", error);
      setTokenABalance(0);
      setTokenBBalance(0);
    }
  }, 300), [selectedToken1Addr, selectedToken2Addr]);

  const calculateAmountB = useCallback(debounce(async () => {
    if (isAmountAEntered && amountA > 0) {
      try {
        const [chainId, tokenAContractAddr, tokenBContractAddr] = await Promise.all([
          getCurrentChainId(),
          getCurrentChainIdTokenAddress(selectedTokenTicker1),
          getCurrentChainIdTokenAddress(selectedTokenTicker2)
        ]);
        const tokenaContractAddr = tokenAContractAddr || selectedToken1Addr;
        const tokenbContractAddr = tokenBContractAddr || selectedToken2Addr;
        if (!tokenaContractAddr || !tokenbContractAddr) {
          console.warn("Invalid token addresses:", tokenaContractAddr, tokenbContractAddr);
          return;
        }
        try {
          const [pricenpoolatob, pricenpoolbtoa] = await Promise.all([
            getValueOutTokenAtoTokenB(1, tokenaContractAddr, tokenbContractAddr),
            getValueOutTokenAtoTokenB(1, tokenbContractAddr, tokenaContractAddr)
          ]);
          setPricenpoolAtoB(Math.max(pricenpoolatob, 0));
          setPricenpoolBtoA(Math.max(pricenpoolbtoa, 0));
          console.log("calculateAmountB_pricenpoolatobAddliquidity", pricenpoolatob, amountA, tokenaContractAddr, tokenbContractAddr);
          console.log("calculateAmountB_pricenpoolbtoaAddliquidity", pricenpoolbtoa, amountA, tokenbContractAddr, tokenaContractAddr);
        } catch (error) {
          console.error("Error fetching price information:", error);
          setPricenpoolAtoB(0);
          setPricenpoolBtoA(0);
        }
        try {
          const pairExitsTokenATokenB = await getPairforTokenATokenB(tokenaContractAddr, tokenbContractAddr);
          console.log("calculateAmountB_getPairforTokenATokenB", pairExitsTokenATokenB);
        } catch (error) {
          console.error("Error fetching pair existence:", error);
        }
        try {
          const getLivePriceValueAtoB = await getLivePricingValue(amountA, selectedTokenTicker1, selectedTokenTicker2);
          setAmountB(Math.max(getLivePriceValueAtoB, 0));
          setIsAmountBEntered(true);
          console.log("calculateAmountB_getLivePriceValueAtoB", getLivePriceValueAtoB);
        } catch (error) {
          console.error("Error fetching live pricing value:", error);
          setAmountB(0);
        }
      } catch (error) {
        console.error("Unexpected error in calculateAmountB:", error);
      }
    }
  }, 300), [amountA, isAmountAEntered, selectedTokenTicker1, selectedTokenTicker2]);

  const fetchUserPoolData = useCallback(debounce(async () => {
    if (!selectedToken1Addr || !selectedToken2Addr) {
      console.warn("Invalid token addresses:", selectedToken1Addr, selectedToken2Addr);
      return;
    }
    try {
      const [balance, perc] = await Promise.all([
        getUserPairBalance(selectedToken1Addr, selectedToken2Addr),
        getPairTotalSupplyAndPoolSharePerc(selectedToken1Addr, selectedToken2Addr)
      ]);
      setUserPairBalance(balance / 10 ** 18);
      setUserPoolSharePerc(perc[1]);
      console.log("userPairBalance", userPairBalance);
      console.log("userPoolSharePerc1", userPoolSharePerc);
    } catch (error) {
      console.error("Error fetching data:", error);
      setUserPairBalance(0);
      setUserPoolSharePerc(0);
    }
  }, 300), [selectedToken1Addr, selectedToken2Addr]);


  useEffect(() => {
    try {
      checkWalletConnection();
      const interval = setInterval(checkWalletConnection, 2000);
      return () => {
        clearInterval(interval);
        checkWalletConnection.cancel();
      };
    } catch (error) {
      console.error('Error in checkWalletConnection useEffect:', error);
    }
  }, [checkWalletConnection]);

  useEffect(() => {
    const fetchDefaultNativeTokenAddress = async () => {
      try {
        const defaultNativeTokenData = await getDefaultNativeTokenAddress();
        if (defaultNativeTokenData?.nativeTokenAddress) {
          const { nativeTokenAddress, nativeTokenTicker, nativeTokenImg } = defaultNativeTokenData;
          console.log("defaultNativeTokenAddress", nativeTokenAddress, nativeTokenTicker, nativeTokenImg);
          setSelectedToken1Addr(nativeTokenAddress);
          setSelectedTokenTicker1(nativeTokenTicker);
          setSelectedTokenImage1(nativeTokenImg);
          const balA = Math.max(await getTokenBalance(nativeTokenAddress), 0);
          setTokenABalance(balA);
          console.log("fetchDefaultNativeTokenAddress", selectedToken1Addr, selectedTokenTicker1, selectedTokenImage1, TokenABalance, balA);
        }
      } catch (error) {
        console.error("Error fetching default native token address:", error);
      }
    };
    fetchDefaultNativeTokenAddress();
  }, []);

  useEffect(() => {
    fetchLiquidityAddData();
    return () => {
      fetchLiquidityAddData.cancel();
    };
  }, [fetchLiquidityAddData]);

  useEffect(() => {
    fetchAllowanceTokentoRouter();
    return () => {
      fetchAllowanceTokentoRouter.cancel();
    };
  }, [fetchAllowanceTokentoRouter]);

  useEffect(() => {
    fetchBalance();
    return () => {
      fetchBalance.cancel();
    };
  }, [fetchBalance]);

  useEffect(() => {
    calculateAmountB();
    return () => {
      calculateAmountB.cancel();
    };
  }, [calculateAmountB]);

  useEffect(() => {
    try {
      fetchUserPoolData();
      return () => {
        fetchUserPoolData.cancel();
      };
    } catch (error) {
      console.error('Error in fetchData useEffect:', error);
    }
  }, [fetchUserPoolData]);


  useEffect(() => {
    document.title = 'Trenddx-Add Liquidity';
    return () => {
      document.title = 'Trenddx';
    };
  }, []);

  return (
    <>
      <ToastContainer style={{ color: 'red', top: '100px' }} />
      <MyNavbar selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
      <div className="jRlUBU" style={{ margin: '120px auto' }}>
        <div className="khwJOa glassmorphism mt-2 ">
          <div className="swapoption d-flex justify-content-between mt-3 align-items-center">
            <Link to="/pool">
              <img src={pharrow} style={{ height: "20px", width: "20px" }} alt="" />
            </Link>
            <button className="swaptext" style={{ fontSize: 20 }}>Add Liquidity</button>
            <img src={phquestion} style={{ height: "20px", width: "20px" }} alt="" />
          </div>
          <div id='swap-page' className='mt-3'>
            <div className='enpFfZ '>
              <div className=' highlight iUsjHb mt-3 hhoFBL1 p-2 morphism'>
                <div className="text-light">You Pay</div>
                <div className='d-flex justify-content-between '>
                  <div style={{ width: '70%' }}>
                    <input id="amountA" type="text" className="currency-input-new" placeholder="0" value={amountA} onChange={handleInputAmountA} />
                  </div>
                  <div>
                    <button className="we" type="button" onClick={() => openModal(1)} style={buttonStyle}>
                      {selectedTokenImage1 ? (
                        <>
                          <img src={selectedTokenImage1} style={{ height: '23px', width: '23px', borderRadius: '50%' }} alt="" />
                          <span className="text-light ms-2">{selectedTokenTicker1}</span>
                        </>
                      ) : (
                        <span className="text-light ms-2">
                          <img src={selectedTokenImage1} height="23px" width="23px" alt="" />
                          <span className="text-light ms-2">{selectedTokenTicker1}</span>
                        </span>
                      )}
                      <img src="/images/dropdown.png" height="23px" width="23px" alt="" />
                    </button>
                  </div>
                </div>
                <div className="d-flex mb-2 justify-content-end px-2 ">
                  <div className='me-4 balance'>Balance:<span>{TokenABalance.toFixed(2)}</span></div>
                  <button className='max' onClick={() => handleMaxClick('A')}>
                    Max
                  </button>
                </div>
              </div>
              <PlusComponent img={plus} />
              <div className='iUsjHb highlight morphism hhoFBL1 p-2 ' id='swapbottom'>
                <div className="m-0 text-light ">You Pay</div>
                <div className='d-flex justify-content-between '>
                  <div style={{ width: '65%' }}>
                    <input id="amountB" type="text" className="currency-input-new" placeholder="0" value={amountB} onChange={handleInputAmountB} />
                  </div>
                  <div>
                    <button className="ws" type="button" onClick={() => openModal(2)} style={button2Style}>
                      {selectedTokenImage2 ? (
                        <>
                          <img src={selectedTokenImage2} style={{ height: '23px', width: '23px', borderRadius: '50%' }} alt="" />
                          <span className="text-light ms-2">{selectedTokenTicker2}</span>
                        </>
                      ) : (
                        <span className="text-light ms-2">Select Token</span>
                      )}
                      <img src="/images/dropdown.png" height="23px" width="23px" alt="" />
                    </button>
                  </div>
                </div>
                <div className="d-flex mb-2 justify-content-end px-2 ">
                  {selectedTokenImage2 ? (<div className='me-4 balance'>Balance:<span>{TokenBBalance.toFixed(2)}</span></div>) : null}
                  <button className="max" onClick={() => handleMaxClick('B')}>
                    Max
                  </button>
                </div>
              </div>
            </div>
          </div>
          {selectedTokenTicker2 === '' ? <div style={{ display: 'none' }}></div> : <div className="_883456cp  mt-2" style={{ border: '0.5px solid #585858' }}>
            <div className="prices-and-pool-share-wrapper">
              <b className="prices-and-pool">Prices and pool share</b>
            </div>
            <div className="_883556cp">
              <div className="_883656cp">
                <div className="_883756cp">
                  <b className="prices-and-pool">{pricenpoolAtoB}</b>
                  <div className="eth-per-wbtc">{selectedTokenTicker2} per {selectedTokenTicker1}</div>
                </div>
                <div className="_883756cp">
                  <b className="prices-and-pool">{pricenpoolBtoA}</b>
                  <div className="eth-per-wbtc">{selectedTokenTicker1} per {selectedTokenTicker2}</div>
                </div>
                <div className="_883756cp">
                  <b className="prices-and-pool">{userAddingLiqPoolSharePerc.toFixed(2)}%</b>
                  <div className="eth-per-wbtc">Share of Pool</div>
                </div>
              </div>
            </div>
          </div>}
          {isWalletConnected.length > 0 ? (
            <div className="mt-2 w-100">
              {!(selectedTokenImage1 && selectedTokenImage2) ? (
                <button className="enterAmountAB mt-2" style={{ width: "100%" }}>
                  Select Tokens
                </button>
              ) : (!isAmountAEntered || !isAmountBEntered ? (
                <button className="enterAmountAB mt-2" style={{ width: "100%" }}>
                  Enter amounts for selected tokens
                </button>
              ) : (
                <>
                  {TokenABalance >= amountA && TokenBBalance >= amountB ? (
                    <>
                      {(tokenAAllowance < amountA || tokenBAllowance < amountB) && (<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {tokenAAllowance < amountA ? (<button className="connect-button w-100" onClick={() => handleApproveToken(amountA, selectedToken1Addr)}>
                          Approve {selectedTokenTicker1}
                        </button>
                        ) : (
                          <button className="connect-button w-100" onClick={() => handleApproveToken(amountB, selectedToken2Addr)}>
                            Approve {selectedTokenTicker2}
                          </button>
                        )}
                      </div>
                      )}
                      {tokenAAllowance >= amountA && tokenBAllowance >= amountB && (
                        <button className="connect-button w-100" onClick={handleAddLiquidity}>
                          Add Liquidity
                        </button>
                      )}
                    </>
                  ) : (
                    <button className="connect-button mt-2 w-100">
                      Insufficient Balance
                    </button>
                  )}
                </>
              ))}
            </div>
          ) : (
            <button className="connect-button mt-2 w-100">
              Connect Wallet
            </button>
          )}
        </div>
        {accesscomponents ? (
          <div className="khwJOa glassmorphism mt-2 mb-2" style={{ border: "none" }}>
            {/* <NewPosition /> */}
            <div className="px-2">
              <div className="_883856ad ">Your Position</div>
              <div className=" _883856ad">
                <div className="d-flex">
                  <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                  <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                  <div>{symbolSelectedToken1Addr}/{symbolSelectedToken2Addr}</div>
                </div>
                <div>{userPairBalance.toFixed(2)}</div>
              </div>
              <div className=" _883856ad">
                <div>Your pool share</div>
                <div>{userPoolSharePerc.toFixed(2)}%</div>
              </div>
              <div className=" _883856ad">
                <div>{symbolSelectedToken1Addr}:</div>
                <div>{userTokenALiquidityAdded}</div>
              </div>
              <div className=" _883856ad">
                <div>{symbolSelectedToken2Addr}:</div>
                <div>{userTokenBLiquidityAdded}</div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: "none" }}></div>
        )}
      </div>
      <div className={`modal fade ${showModal ? "show" : ""}`} style={{
        display: showModal ? "block" : "none",
        zIndex: "99999",
      }} id="supplyModal" tabIndex="-1" aria-labelledby="supplyModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className=" _883856ad">
                <div>You will receive</div>
                <div><img src={cross} data-bs-dismiss="modal" aria-label="Close" style={{ height: '24px', width: '24px' }} alt='' /></div>
              </div>
              <div className="p-2 flex-column">
                <div className="_883856tt ">
                  <div className="div fs-1">141421</div>
                  <img alt="" src={phquestion} style={{ height: '24px', width: '24px' }} />
                  <img alt="" src={phquestion} style={{ height: '24px', width: '24px' }} />
                </div>
                <div className="_883856tt">{symbolSelectedToken1Addr}/{symbolSelectedToken2Addr} Pool Tokens</div>
              </div>
              <div className=" _883856ad">
                <div>{symbolSelectedToken1Addr} Deposited</div>
                <div className="d-flex">
                  <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                  <div>141400</div>
                </div>
              </div>
              <div className=" _883856ad">
                <div>{symbolSelectedToken2Addr} Deposited</div>
                <div className="d-flex">
                  <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                  <div>200000</div>
                </div>
              </div>
              <div className=" _883856ad">
                <div>Rates:</div>
                <div>1 {symbolSelectedToken1Addr} = 2 Token2</div>
              </div>
              <div className=" _883856ad">
                <div></div>
                <div>1 {symbolSelectedToken2Addr} = 0.5{symbolSelectedToken1Addr}</div>
              </div>
              <div className=" _883856ad">
                <div>Share of Pool:</div>
                <div>100%</div>
              </div>
              <button className="connect-button mt-5 w-100">
                {`Create Supply`}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal1} onHide={() => closeModal(1)}
        style={{ borderRadius: '15px' }}
        dialogClassName='modal-dialog-centered'>
        <Modal.Body className='p-0 modal-body'>
          <SelectToken2 showModal1={showModal1} closeModal={() => closeModal(1)} onSelectToken={(image, ticker) => handleTokenSelect(image, ticker, 1)} />
        </Modal.Body>
      </Modal>
      <Modal show={showModal2} onHide={() => closeModal(2)}
        style={{ borderRadius: '15px' }}
        dialogClassName='modal-dialog-centered'>
        <Modal.Body className='p-0 modal-body'>
          <SelectToken2 showModal2={showModal2} closeModal={() => closeModal(2)} onSelectToken={(image, ticker) => handleTokenSelect(image, ticker, 2)} />
        </Modal.Body>
      </Modal>
      <Modal show={showNestedModal} onHide={closeNestedModal}>
        <div className='gnwYND bFCsHr'>
          <Modal.Header style={{ borderBottom: '1px solid #585858' }}>
            <img src={pharrow} style={{ height: "20px", width: "20px" }} alt="" />
            <span className='text-light'>Import Tokens</span>
            <img src={cross} style={{ height: '24px', width: '24px' }} onClick={closeNestedModal} alt='' />
          </Modal.Header>
          <Modal.Body className='p-0 modal-body'>
            <ImportModalContent closeNestedModal={closeNestedModal} closeModal={closeModal} />
          </Modal.Body>
        </div>
      </Modal>

    </>
  );

};

export default AddLiquidty;