import React, { useState } from 'react';
import './volume.css';
import tennisicon from '../Assets/images2/teenyicons_tick-solid.png';

const Volume = () => {
    const [selectedVolume, setSelectedVolume] = useState('1H');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleSelectVolume = (volume) => {
        setSelectedVolume(volume);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const options = [
        { key: 1, volumeName: '1H' },
        { key: 2, volumeName: '1D' },
        { key: 3, volumeName: '1W' },
        { key: 4, volumeName: '1M' },
        { key: 5, volumeName: '1Y' }
    ];

    return (
        <>
            <button variant="primary" className="px-3 tab-button text-light m-1" onClick={toggleDropdown}>
                {selectedVolume}-volume
                <img src="/images/dropdown.png" height="23px" width="23px" alt="" />
            </button>
            {isDropdownOpen && (
                <div className="d-volume">
                    {options.map((option) => (
                        <div key={option.key} className="d-volume-child" onClick={() => handleSelectVolume(option.volumeName)}>
                            <div className="h-volume-wrapper">
                                <div className="h-volume">{option.volumeName} volume</div>
                            </div>
                            {option.volumeName === selectedVolume && (
                                <img className="teenyiconstick-solid" alt="" src={tennisicon} />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Volume;
