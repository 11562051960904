import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import TokenTable from '../ExploreContent/Tokentable';
import Pooltable from '../ExploreContent/Pooltable';
import Transactionstable from '../ExploreContent/Transactionstable';
import Volume from '../Dropdown/Volume';
import MyNavbar from './MyNavbar';
import NetworkOverlay from '../Dropdown/NetworkOverlay';
import BarChart from '../Dropdown/BarChart';
import Context from '../Context/Context';

const Explore = () => {

    const [selectedTab, setSelectedTab] = useState('pills-home');
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const { selectedNetwork, setSelectedNetwork, timeFrame, setTimeFrame, searchTokentabledata, searchPooltabledata, hoveredValue, hoveredDate, avgvalue, isBarHovered } = useContext(Context)
    const [filteredData, setFilteredData] = useState([]);
    const [filteredPoolData, setFilteredPoolData] = useState([]);
    const [searchQueryToken, setSearchQueryToken] = useState("");
    const [searchQueryPool, setSearchQueryPool] = useState("");

    console.log("searchPooltabledata", searchPooltabledata);
    console.log("timeFrame", timeFrame);
    console.log("filteredPoolData", filteredPoolData);

    const handleSelectNetwork = (network) => {
        setSelectedNetwork(network);
    };

    const handleSearchClick = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const handleTabSelect = (key) => {
        setSelectedTab(key);
    };

    const handleTimeFrameClick = (timeFrame) => {
        setTimeFrame(timeFrame);
    };

    const handleSearch = (query) => {
        let filteredData;
        if (query.length > 0) {
            filteredData = searchTokentabledata.filter(item =>
                (item.symbol && item.symbol.toLowerCase().includes(query)) ||
                (item.name && item.name.toLowerCase().includes(query))
            );
        } else {
            filteredData = searchTokentabledata;
        }
        setFilteredData(filteredData);
    };

    const handleSearchPool = (query) => {
        let filteredPoolData;
        if (query.length > 0) {
            filteredPoolData = searchPooltabledata.filter(item =>
                (item.pairAddress && item.pairAddress.toLowerCase().includes(query)) ||
                (item.pairName && item.pairName.toLowerCase().includes(query)));
        } else {
            filteredPoolData = searchPooltabledata;
            console.log("filteredDataexplore", filteredPoolData);
        }
        setFilteredPoolData(filteredPoolData);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    }

    const debouncedSearch = useCallback(debounce((query) => handleSearch(query), 1000), [searchTokentabledata]);
    const debouncedSearchPool = useCallback(debounce((query) => handleSearchPool(query), 1000), [searchPooltabledata]);

    const handleSearchChangeToken = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQueryToken(query);
        debouncedSearch(query);
    };

    const handleSearchChangePool = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQueryPool(query);
        debouncedSearchPool(query);
    };

    useEffect(() => {
        document.title = 'TrendDx-Explore';
        return () => {
            document.title = 'TrendDx';
        };
    }, []);

    return (
        <>
            <MyNavbar selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
            {/* Graph section */}
            <div className="container glassmorphism  mt-3 " style={{ borderRadius: '16px' }}>
                <div className="row px-3 ">
                    <div className="col-md-12 p-2 text-light ">
                        <div className="d-flex align-items-center justify-content-center">
                            <p className='w-100' style={{ color: '#9b9797' }}>TrendDx Volume</p>
                            <div className="parent-frame">
                                <button className={timeFrame === 'D' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('D')}>
                                    D
                                </button>
                                <button className={timeFrame === 'W' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('W')}>
                                    W
                                </button>
                                <button className={timeFrame === 'M' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('M')}>
                                    M
                                </button>
                                <button className={timeFrame === 'Y' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('Y')}>
                                    Y
                                </button>
                            </div>
                        </div>
                        <div className="million d-flex align-items-center justify-content-between">
                            <div>
                                <h2>${isBarHovered ? hoveredValue : avgvalue}</h2>
                                <p style={{ color: '#9b9797' }}>{isBarHovered ? hoveredDate : timeFrame === 'D' ? 'Past Month' : timeFrame === 'W' ? 'Past Year' : timeFrame === 'M' ? 'All time' : timeFrame === 'Y' ? 'All time' : ''}</p>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <BarChart />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <Tab.Container id="pills-tab" defaultActiveKey={selectedTab} onSelect={handleTabSelect}>
                    <div className="table-tab mt-4">
                        <Nav variant="pills" style={{ backgroundColor: 'transparent', marginBottom: '10px' }}>
                            <Nav.Item>
                                <Nav.Link eventKey="pills-home" className={selectedTab === 'pills-home' ? 'customtab' : 'navlinks'}>Tokens</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="pills-profile" className={selectedTab === 'pills-profile' ? 'customtab' : 'navlinks'}>Pools</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="pills-contact" className={selectedTab === 'pills-contact' ? 'customtab' : 'navlinks'}>Transactions</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className=''>
                            <Tab.Pane eventKey="pills-home">
                                <div className="tpt-buttons d-flex align-items-center justify-content-center">
                                    <NetworkOverlay selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
                                    <Volume />
                                    <div className='hrxVYA' style={{ cursor: 'pointer' }}>
                                        <div className='d-flex align-item-center' style={{ padding: '8px' }}><img src="/images/search.png" height="20px" width="20px" alt="" onClick={handleSearchClick} /></div>
                                        {isSearchVisible && <input type='search' className='px-2' value={searchQueryToken} onChange={handleSearchChangeToken} autoFocus style={{ color: 'white', width: "100%", background: 'transparent', outline: 'none', border: 'transparent' }} placeholder='Search here' />}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pills-profile">
                                <div className="tpt-buttons d-flex align-items-center justify-content-center" style={{ gap: '0px 4px' }}>
                                    <NetworkOverlay selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
                                    <div className='hrxVYA' style={{ cursor: 'pointer' }}>
                                        <div className='d-flex align-item-center' style={{ padding: '8px' }}><img src="/images/search.png" height="20px" width="20px" alt="" onClick={handleSearchClick} /></div>
                                        {isSearchVisible && <input type='search' className='px-2' value={searchQueryPool} onChange={handleSearchChangePool} autoFocus style={{ color: 'white', width: "100%", background: 'transparent', outline: 'none', border: 'transparent' }} placeholder='Search here' />}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="pills-contact">
                                <div className="tpt-buttons d-flex align-items-center justify-content-center">
                                    <NetworkOverlay selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
            {selectedTab === 'pills-home' && <TokenTable filteredData={filteredData} />}
            {selectedTab === 'pills-profile' && <Pooltable filteredPoolData={filteredPoolData} />}
            {selectedTab === 'pills-contact' && <Transactionstable />}
        </>
    );

};

export default Explore;