import React, { useContext, useEffect, useState } from 'react';
import Total from '../Analytics/Total';
import LiquidityHub from '../LiquidtyContent/LiquidityHub';
import MyNavbar from './MyNavbar';
import Context from '../Context/Context';
import classNames from 'classnames';

const Analytics = () => {

  const [activeTab, setActiveTab] = useState('total');
  const { selectedNetwork, setSelectedNetwork } = useContext(Context);

  const handleSelectNetwork = (network) => {
    setSelectedNetwork(network);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    document.title = 'TrendDx-Analytics';
    return () => {
      document.title = 'TrendDx';
    };
  }, []);

  return (
    <>
      <MyNavbar selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
      <div className="trendswap-analytics-parent container mt-3">
        <b className="trendswap-analytics tswap">TrendDx Analytics</b>
        <div className="parent-frame" style={{ border: '1px solid #9b9797', height: '100%', borderRadius: '8px' }}>
          <div
            className={classNames('total-wrapper-total', { 'total-wrapper': activeTab === 'liquidityHub' })}
            onClick={() => handleTabClick('liquidityHub')}
          >
            <div className={classNames('trendswap-analytics', { 'active1': activeTab === 'liquidityHub' })}>
              Liquidity Hub
            </div>
          </div>
          <div
            className={classNames('total-wrapper-total', { 'total-wrapper': activeTab === 'total' })}
            onClick={() => handleTabClick('total')}
          >
            <div className={classNames('trendswap-analytics', { 'active1': activeTab === 'total' })}>
              Total
            </div>
          </div>
        </div>
      </div>
      {activeTab === 'total' && <Total />}
      {activeTab === 'liquidityHub' && <LiquidityHub />}
    </>
  );

};

export default Analytics;