import React, { useState } from 'react';
import group from '../Assets/images2/Group.png';
import group1 from '../Assets/images2/Group (1).png';
import AllCryptosTable from './AllCryptosTable';
import NewListingTable from './NewListingTable';
import FavouritesTable from './FavouritesTable';

const TotalTokens = () => {

  const [activeTab, setActiveTab] = useState('allCryptos');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getTabStyle = (tabName) => ({
    color: activeTab === tabName ? 'rgb(64, 252, 254)' : '#9b9797',
  });

  const data = [
    { ticker: 'USDC.e', price: '$2,969.244', change: '+1.34%', image: group },
    { ticker: 'WETH', price: '$40,000.00', change: '-0.5%', image: group1 },
    { ticker: 'MATIC', price: '$2,969.244', change: '+1.34%', image: group },
    { ticker: 'BTC', price: '$40,000.00', change: '-0.5%', image: group1 },
    { ticker: 'DAi', price: '$2,969.244', change: '+1.34%', image: group },
  ];

  return (
    <>
      <div className="container tokens mt-2 d-flex justify-content-evenly flex-column" style={{ height: '120px', borderRadius: '15px', background: 'linear-gradient(180deg, #032e50, #101525), linear-gradient(180deg, rgba(217, 217, 217, 0.08), rgba(217, 217, 217, 0.08))' }}>
        <p className="m-0 ms-3" style={{ color: 'rgba(155, 151, 151, 1)', fontWeight: '800' }}>24h MOST VOLUME</p>
        <div className="topMoversContent">
          <div>
            {data.map((item, index) => (
              <div key={item.ticker} className="topMoverItem ms-3">
                <div className="jss66 currencyLogo">
                  <img className="cryptocurrency-colorusdc-icon group-icon" alt="" src={item.image} />
                </div>
                <div className="jss67">
                  <small className="ticker">{item.ticker}</small>
                  <div className="d-flex align-items-center justify-content-center">
                    <small className="ticker_value">{item.price}</small>
                    <div className="topMoverText">
                      <span>{item.change}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container d-flex align-items-center justify-content-between mt-3">
        <div className="d-flex px-0" style={{ gap: '0px 20px' }}>
          <p className={`m-0 top-tokens fw-bold ${activeTab === 'allCryptos' ? 'active1' : ''}`} style={getTabStyle('allCryptos')} onClick={() => handleTabClick('allCryptos')}>
            All Cryptos
          </p>
          <p className={`m-0 top-tokens fw-bold ${activeTab === 'favorites' ? 'active1' : ''}`} style={getTabStyle('favorites')} onClick={() => handleTabClick('favorites')}>
            Favorites
          </p>
          <p className={`m-0 top-tokens fw-bold ${activeTab === 'newListings' ? 'active1' : ''}`} style={getTabStyle('newListings')} onClick={() => handleTabClick('newListings')}>
            New Listings
          </p>
        </div>
      </div>
      {/* Conditionally render content based on the active tab */}
      {activeTab === 'allCryptos' && <AllCryptosTable />}
      {activeTab === 'favorites' && <FavouritesTable />}
      {activeTab === 'newListings' && <NewListingTable />}
    </>
  );

};

export default TotalTokens;