import Web3 from "web3";



export const getRpcUrl = async () => {
    const rpcConfig = {
        43113: 'https://avalanche-fuji-c-chain-rpc.publicnode.com',
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    };
    try {
        const currentChainId = await getCurrentChainId();
        const rpcUrl = rpcConfig[currentChainId];
        console.log('getRpcUrl:', rpcUrl);
        return rpcUrl;
    } catch (error) {
        console.error('getRpcUrl_ERROR:', error);
        return undefined;
    }
}

export const getCurrentChainId = async () => {
    const provider = getCurrentProvider();
    if (!provider) {
        console.error("No Ethereum provider found. Install MetaMask.");
        return;
    }
    try {
        const web3 = await new Web3(provider);
        const currentChainId = Number(await web3.eth.net.getId());
        console.log("getCurrentChainId", currentChainId);
        return currentChainId;
    } catch (error) {
        console.error("getCurrentChainIdERROR", error);
    }
}

export const getCurrentProvider = () => {
    let provider;
    try {
        if (window.ethereum) {
            provider = window.ethereum;
        } else if (window.web3) {
            provider = window.web3.currentProvider;
        }
    } catch (error) {
        console.error("Error getting current provider:", error);
    }
    if (!provider) {
        console.log("Non-Ethereum browser detected. You should install MetaMask.");
    }
    return provider;
}

export const getCurrentAccount = async () => {
    try {
        let currentProvider = await getCurrentProvider();
        await currentProvider.request({ method: "eth_requestAccounts" });
        const accounts = await currentProvider.request({
            method: "eth_accounts",
        });
        const account = accounts[0];
        console.log("getCurrentAccount", account);
        return account;
    } catch (error) {
        console.log(error);
    }
}

export const getImportedAddressExplorer = async () => {
    const currentChainId = await getCurrentChainId();
    console.log("getCurrentTxExplorerCurrentChainId", currentChainId);
    const currentChainIdInHex = Web3.utils.toHex(currentChainId);
    const networkExplorers = [
        { key: 1, networkExplorer: "https://sepolia.etherscan.io/address/", chainId: '0x1' },
        { key: 2, networkExplorer: "https://sepolia.arbiscan.io/address/", chainId: '0xa4b1' },
        { key: 3, networkExplorer: "https://goerli-optimism.etherscan.io/address/", chainId: '0xa' },
        { key: 4, networkExplorer: "https://amoy.polygonscan.com/address/", chainId: '0x13881' },
        { key: 5, networkExplorer: "https://testnet.bscscan.com/address/", chainId: '0x61' },
        { key: 6, networkExplorer: "https://testnet.snowtrace.io/address/", chainId: '0xa869' },
        { key: 7, networkExplorer: "https://alfajores.celoscan.io/address/", chainId: '0xa4ec' },
        { key: 8, networkExplorer: "https://goerli.basescan.org/address/", chainId: '0x2105' }
    ];
    const currentNetworkExplorer = networkExplorers?.find(
        explorer => explorer.chainId.toLowerCase() === currentChainIdInHex.toLowerCase()
    );
    console.log("currentNetworkExplorer", currentNetworkExplorer.networkExplorer);
    return currentNetworkExplorer ? currentNetworkExplorer.networkExplorer : null;
};

export const getCurrentTxExplorer = async () => {
    const currentChainId = await getCurrentChainId();
    console.log("getCurrentTxExplorerCurrentChainId", currentChainId);
    const currentChainIdInHex = Web3.utils.toHex(currentChainId);
    const networkExplorers = [
        { key: 1, networkExplorer: "https://sepolia.etherscan.io/tx/", chainId: '0x1' },
        { key: 2, networkExplorer: "https://sepolia.arbiscan.io/tx/", chainId: '0xa4b1' },
        { key: 3, networkExplorer: "https://goerli-optimism.etherscan.io/tx/", chainId: '0xa' },
        { key: 4, networkExplorer: "https://amoy.polygonscan.com/tx/", chainId: '0x13881' },
        { key: 5, networkExplorer: "https://testnet.bscscan.com/tx/", chainId: '0x61' },
        { key: 6, networkExplorer: "https://testnet.snowtrace.io/tx/", chainId: '0xa869' },
        { key: 7, networkExplorer: "https://alfajores.celoscan.io/tx/", chainId: '0xa4ec' },
        { key: 8, networkExplorer: "https://goerli.basescan.org/tx/", chainId: '0x2105' }
    ];
    const currentNetworkExplorer = networkExplorers?.find(
        explorer => explorer.chainId.toLowerCase() === currentChainIdInHex.toLowerCase()
    );
    console.log("currentNetworkExplorer", currentNetworkExplorer.networkExplorer);
    return currentNetworkExplorer ? currentNetworkExplorer.networkExplorer : null;
};





export const URLDOMAIN = "https://trenddx.io/";

export const TRNDContractAddress = "0x97779c0b556ef2f33cb3e8350e36f978ef0f2c53";

export const APISTORETRANSACTIONS = `${URLDOMAIN}api/transction/storetransctions`;

export const APIGETALLTRANSACTION = `${URLDOMAIN}api/transction/getalltransaction`;

export const APIGETTOKENDATA = `${URLDOMAIN}api/tokens/gettokendata`;

export const APIUPDATETOKENDATA = `${URLDOMAIN}api/tokens/updatetokendata`;

export const APIGETPOOLDATA = `${URLDOMAIN}api/pools/getpooldata`;

export const APIUPDATEPOOLDATA = `${URLDOMAIN}api/pools/updatepooldata`;





getCurrentAccount()
getCurrentTxExplorer()
getImportedAddressExplorer()
getRpcUrl()