import React, { useState } from 'react'
import Context from '../Context/Context';

export default function Dropdownstate({ children }) {

  const [selectedNetwork, setSelectedNetwork] = useState({ name: 'Ethereum', icon: './images/sprint.png' });
  const [selectedToken1, setSelectedToken1] = useState({});
  const [selectedToken2, setSelectedToken2] = useState({});
  const [selectedToken1Address, setSelectedToken1Address] = useState('');
  const [selectedToken2Address, setSelectedToken2Address] = useState('');
  const [liquidityselectedToken1, setLiquiditySelectedToken1] = useState({});
  const [liquidityselectedToken2, setLiquiditySelectedToken2] = useState({});
  const [liquidityselectedToken1Address, setLiquiditySelectedToken1Address] = useState('');
  const [liquidityselectedToken2Address, setLiquiditySelectedToken2Address] = useState('');
  const [removeLqselectedToken1, setRemoveLqSelectedToken1] = useState({});
  const [removeLqselectedToken2, setRemoveLqSelectedToken2] = useState({});
  const [removelqSelectedToken1Address, setRemoveLqSelectedToken1Address] = useState('');
  const [removelqSelectedToken2Address, setRemoveLqSelectedToken2Address] = useState('');
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [currentImportedTokenAddress, setCurrentImportedTokenAdderess] = useState('')
  const [customValue, setCustomValue] = useState('0.5');
  const [customValue1, setCustomValue1] = useState('10');
  const [activeSection, setActiveSection] = useState('overview');
  const [activeTab, setActiveTab] = useState('overview');
  const [searchtabledata, setSearchtabledata] = useState();
  const [searchTokentabledata, setSearchTokentabledata] = useState();
  const [searchPooltabledata, setSearchPooltabledata] = useState();
  const [timeFrame, setTimeFrame] = useState('D');
  const [timeFrameVolume, setTimeFrameVolume] = useState('1M');
  const [favorites, setFavorites] = useState([]);
  const [hoveredValue, setHoveredValue] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [totalAmountAUSD, setTotalAmountAUSD] = useState(0);
  const [avgvalue, setAvgvalue] = useState(null);
  const [isBarHovered, setIsBarHovered] = useState(false);

  const openNestedModal = () => {
    setShowNestedModal(true);
  };
  const closeNestedModal = () => {
    setShowNestedModal(false);
  };

  return (
    <Context.Provider value={{
      selectedNetwork, setSelectedNetwork,
      selectedToken1, setSelectedToken1,
      selectedToken2, setSelectedToken2,
      selectedToken1Address, setSelectedToken1Address,
      selectedToken2Address, setSelectedToken2Address,
      liquidityselectedToken2Address, setLiquiditySelectedToken2Address,
      liquidityselectedToken1Address, setLiquiditySelectedToken1Address,
      liquidityselectedToken2, setLiquiditySelectedToken2,
      liquidityselectedToken1, setLiquiditySelectedToken1,
      removeLqselectedToken1, setRemoveLqSelectedToken1,
      removeLqselectedToken2, setRemoveLqSelectedToken2,
      removelqSelectedToken1Address, setRemoveLqSelectedToken1Address,
      removelqSelectedToken2Address, setRemoveLqSelectedToken2Address,
      showNestedModal, openNestedModal, closeNestedModal,
      currentImportedTokenAddress, setCurrentImportedTokenAdderess,
      customValue, setCustomValue,
      customValue1, setCustomValue1,
      activeSection, setActiveSection,
      activeTab, setActiveTab,
      searchtabledata, setSearchtabledata,
      searchTokentabledata, setSearchTokentabledata,
      favorites, setFavorites,
      searchPooltabledata, setSearchPooltabledata,
      timeFrame, setTimeFrame,
      hoveredValue, setHoveredValue,
      totalAmountAUSD, setTotalAmountAUSD,
      hoveredDate, setHoveredDate,
      avgvalue, setAvgvalue,
      isBarHovered, setIsBarHovered,
      timeFrameVolume, setTimeFrameVolume
    }}>
      {children}
    </Context.Provider>
  )

}