import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import MyNavbar from "../Component/MyNavbar";
import SwapPage from "../Dropdown/SwapPage";
import Context from "../Context/Context";
import TradePair from "../PoolContent/TradePair";

const DisclaimerModal = ({ onClose }) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const handleCheck1 = () => setIsChecked1(!isChecked1);
  const handleCheck2 = () => setIsChecked2(!isChecked2);

  const handleConfirm = () => {
    try {
      if (isChecked1 && isChecked2) {
        onClose();
        localStorage.setItem("disclaimerAccepted", "true");
      } else {
        console.error("Both checkboxes must be checked to confirm.");
      }
    } catch (error) {
      console.error("Error setting disclaimer acceptance:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content1">
        <div>
          <p style={{ fontSize: "16px" }}>Disclaimer</p>
          <p style={{ fontSize: "12px" }}>
            Please check the boxes below to confirm your agreement to the
            TrendDx{" "}
            <Link to={"/terms-and-conditions"} style={{ color: "#40fcfe" }}>
              Terms and conditions
            </Link>
          </p>
        </div>
        <div className="mt-4">
          {[
            { checked: isChecked1, handler: handleCheck1 },
            { checked: isChecked2, handler: handleCheck2 },
          ].map((item, index) => (
            <div className="check-box-parent mt-3" key={index}>
              <input
                type="checkbox"
                className="mt-1"
                checked={item.checked}
                onChange={item.handler}
              />
              <div className="i-have-read ms-2" onClick={item.handler}>
                I have read and understood and do hereby agree to be legally
                bound as a ‘User’ under, the Terms, including all future
                amendments thereto. Such agreement is irrevocable and will apply
                to all of my uses of the Site without me providing confirmation
                in each specific instance.
              </div>
            </div>
          ))}
        </div>
        <div
          className="connect-button mt-4 mb-2"
          onClick={handleConfirm}
          style={{
            width: "100%",
            backgroundColor:
              isChecked1 && isChecked2 ? "rgba(64, 252, 254, 0.5)" : "",
          }}
        >
          <button className="connect-wallet">
            <b>Confirm</b>
          </button>
        </div>
      </div>
    </div>
  );
};

const Swap = () => {
  const { selectedNetwork, setSelectedNetwork } = useContext(Context);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const handleSelectNetwork = (network) => setSelectedNetwork(network);
  const handleCloseDisclaimer = () => setShowDisclaimer(false);

  useEffect(() => {
    try {
      const disclaimerAccepted = localStorage.getItem("disclaimerAccepted");
      setShowDisclaimer(disclaimerAccepted ? false : true);
    } catch (error) {
      console.error("Error checking disclaimer acceptance:", error);
      setShowDisclaimer(true);
    }
  }, []);

  useEffect(() => {
    document.title = "TrendDx-Swap";
    return () => {
      document.title = "TrendDx";
    };
  }, []);

  return (
    <>
      <MyNavbar
        selectedNetwork={selectedNetwork}
        onSelectNetwork={handleSelectNetwork}
      />
      <div className="jRlUBU" style={{ margin: "65px auto" }}>
        {showDisclaimer && <DisclaimerModal onClose={handleCloseDisclaimer} />}
        <div className="khwJOa " style={{ zIndex: 5 }}>
          <SwapPage />
        </div>
        <div>
          <TradePair />
        </div>
      </div>
    </>
  );
};

export default Swap;
