

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getCurrentChainIdAllTokens } from '../ContractAction/TrendswapAction';

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState('');
  const [allTokens, setAllTokens] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchCurrentChainAllTokens = async () => {
      const allChainTokens = await getCurrentChainIdAllTokens();
      setAllTokens(allChainTokens);
    };
    fetchCurrentChainAllTokens();
  }, []);

  const filteredTokens = allTokens.filter(
    (token) =>
      token.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      token.ticker.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  const handleInput = (event) => {
    const query = event.target.value;
    setSearchValue(query);
    if (query.length > 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleKeyPress = useCallback((event) => {
    if (event.key === '/' && document.activeElement !== inputRef.current) {
      event.preventDefault();
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="search-bar-container" ref={searchRef}>
      <div className="search-bar">
        <div className="search-icon-container">
          <img src="/images/search.png" style={{ height: '23px', width: '23px' }} alt="" />
        </div>
        <input
          type="text"
          className="search-input"
          placeholder="Search tokens and NFT collections"
          value={searchValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInput={handleInput}
          ref={inputRef}
        />
        <img src="/images/forward.png" style={{ height: '20px', width: '20px' }} alt="" />
      </div>
      {isOpen && (
        <div className="search-dropdown">
          {searchValue && filteredTokens.length === 0 && (
            <div className="text-light">No tokens found</div>
          )}
          {filteredTokens.map((token, index) => (
            <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index}>
              <div className="bFCsHr">
                <img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt="" />
              </div>
              <div className="eENUcJ">
                <div className="dKubqp dKubqptext cPCYrp">
                  <a href="#">{token.name}</a>
                </div>
                <div className="css-1m0dqmt jgbRhf">{token.ticker}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;




