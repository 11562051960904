import Swap from './Swap';

function Main() {

  return (
    <>
      <Swap />
    </>
  );

}

export default Main;