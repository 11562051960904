import React, { useState, useEffect, useContext } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { APIGETPOOLDATA, getCurrentChainId } from '../ContractAction/ContractDependency';
import Context from '../Context/Context';

let cachedAnalyticsTopPairsData = [];

const Total_Pair = ({ filteredData }) => {

  const [tokenData, setTokenData] = useState(cachedAnalyticsTopPairsData);
  const [pageSize, setPageSize] = useState(5);
  const { searchtabledata, setSearchtabledata } = useContext(Context);
  console.log("searchtabledata", searchtabledata);

  const formatValue = (value) => {
    const absValue = Math.abs(value);
    let formattedValue = absValue;
    if (absValue >= 1e12) {
      formattedValue = (value / 1e12).toFixed(2) + "T";
    } else if (absValue >= 1e9) {
      formattedValue = (value / 1e9).toFixed(2) + "B";
    } else if (absValue >= 1e6) {
      formattedValue = (value / 1e6).toFixed(2) + "M";
    } else if (absValue >= 1e3) {
      formattedValue = (value / 1e3).toFixed(2) + "K";
    } else {
      formattedValue = absValue.toFixed(2);
    }
    return `$${formattedValue}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <>
            <div style={{ textAlign: 'left', paddingLeft: "20px", }}>
              {'# Name'}
            </div>
          </>
        ),
        accessor: 'pairName',
        Cell: ({ value }) => <div style={{ textAlign: 'left', paddingLeft: "20px", minWidth: '150px' }}>{value}</div>,
      },
      {
        Header: () => (
          <>
            <div style={{ textAlign: 'center', fontWeight: '500', }}>
              <span> {' Liquidity'} </span>
            </div>
          </>
        ),
        accessor: 'TVL',
        Cell: ({ value }) => <div style={{ textAlign: 'start', fontWeight: '500', minWidth: '100px' }}>{formatValue(value)}</div>,
      },
      {
        Header: () => (
          <>
            <div style={{ textAlign: 'center', fontWeight: '500', }}>
              {' 24h volume'}
            </div>
          </>
        ),
        accessor: 'oneDayVol',
        Cell: ({ value }) => <div style={{ textAlign: 'start', fontWeight: '500', minWidth: '100px' }}>{formatValue(value)}</div>,
      },
      {
        Header: () => (
          <>
            <div style={{ textAlign: 'center', fontWeight: '500', }}>
              {' 7 day volume'}
            </div>
          </>
        ),
        accessor: 'sevenDayVol',
        Cell: ({ value }) => <div style={{ textAlign: 'start', fontWeight: '500', minWidth: '150px' }}>{formatValue(value)} </div>
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page,
    pageCount,
    state: { pageIndex },
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
  } = useTable({ columns, data: filteredData.length > 0 ? filteredData : tokenData, initialState: { pageIndex: 0, pageSize } }, useSortBy, usePagination);

  const totalRows = filteredData.length;
  const startRecordIndex = pageIndex * pageSize + 1;
  const endRecordIndex = Math.min((pageIndex + 1) * pageSize, totalRows);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentChainId = await getCurrentChainId();
        const PoolLiveInfoDetails = await fetch(`${APIGETPOOLDATA}?chainId=${currentChainId}`);
        if (!PoolLiveInfoDetails.ok) {
          console.error('PoolLiveInfoDetailsTotal_PairResponseNotOk');
        }
        const resPoolLiveInfoDetails = await PoolLiveInfoDetails.json();
        const data = resPoolLiveInfoDetails.data;
        const sortedPairs = data.sort((a, b) => parseFloat(b.TVL) - parseFloat(a.TVL));
        cachedAnalyticsTopPairsData = sortedPairs;
        setTokenData(sortedPairs);
        console.log("AllPoolLiveInfoDetailsTotal_Pair:", sortedPairs);
      } catch (error) {
        console.error('AllPoolLiveInfoDetailsTotal_PairError:', error);
      }
    }
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setSearchtabledata(tokenData);
  }, [tokenData])

  useEffect(() => {
    setTablePageSize(pageSize);
  }, [setTablePageSize, pageSize]);

  return (
    <div className='container mt-2 px-0'>
      <div className='table-responsive' style={{ borderRadius: '15px' }}>
        <Table {...getTableProps()} className='table  '>
          <thead className='tablestruct' style={{ color: 'rgba(155, 151, 151, 1)' }}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`text-center ${column.isSorted ? 'sorted-column' : ''}`}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="asc" class="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                            <line x1="12" y1="19" x2="12" y2="5"></line>
                            <polyline points="5 12 12 5 19 12"></polyline>
                          </svg>) : (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="desc" class="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <polyline points="19 12 12 19 5 12"></polyline>
                          </svg>
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ background: 'linear-gradient(180deg, #032E50 0%, #101525 100%), linear-gradient(180deg, rgba(217, 217, 217, 0.075) 0%, rgba(217, 217, 217, 0.075) 100%)' }}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className='glassmorphism text-center table-row' style={{ color: "white" }}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {filteredData.length > 0 && <div className='d-flex justify-content-end align-items-center gap-3'>
        <div className="custom-dropdown d-flex align-items-center justify-content-center">
          Records per page:
          <div className="dropdown-toggle" onClick={() => document.getElementById("dropdown-menu").classList.toggle("show")}>
            {pageSize}
          </div>
          <div id="dropdown-menu" className="dropdown-menu">
            <div className="dropdown-item" onClick={() => { setPageSize(3); document.getElementById("dropdown-menu").classList.remove("show"); }}>3</div>
            <div className="dropdown-item" onClick={() => { setPageSize(5); document.getElementById("dropdown-menu").classList.remove("show"); }}>5</div>
            <div className="dropdown-item" onClick={() => { setPageSize(10); document.getElementById("dropdown-menu").classList.remove("show"); }}>10</div>
          </div>
        </div>
        <div className='pageCount'>
          {startRecordIndex}-{endRecordIndex} of {totalRows}
        </div>
        <Pagination style={{ marginBottom: "0px" }}>
          <Pagination.Prev onClick={previousPage} disabled={pageIndex === 0}>
            <span aria-hidden="true">&laquo;</span>
          </Pagination.Prev>
          <Pagination.Next onClick={nextPage} disabled={pageIndex === pageCount - 1}>
            <span aria-hidden="true">&raquo;</span>
          </Pagination.Next>
        </Pagination>
      </div>
      }
    </div>
  );

};

export default Total_Pair;