import React, { useContext, useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import Context from '../Context/Context';
import { getCurrentChainId, URLDOMAIN } from '../ContractAction/ContractDependency';

Chart.register(...registerables);


const BarChart = () => {

  const { timeFrame, setHoveredValue, hoveredDate, setHoveredDate, avgvalue, setAvgvalue, setIsBarHovered } = useContext(Context)
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [currentChainId, setCurrentChainId] = useState('');
  const [chartData, setChartData] = useState([]);

  console.log(hoveredDate, "hoveredDate");
  console.log("averagevalue", avgvalue);

  useEffect(() => {
    const fetchCurrentChainId = async () => {
      try {
        const currentChainId = await getCurrentChainId();
        // setCurrentChainId(currentChainId);  //NOTE: dynamic current chain id 
        setCurrentChainId(43113); // Example static value for testing
        console.log("Current Chain ID:", currentChainId);
      } catch (error) {
        console.error("Error fetching current chain ID:", error);
      }
    };
    fetchCurrentChainId();
  }, []);

  useEffect(() => {
    let apiUrl;
    if(!currentChainId){
      return;
    }
    switch (timeFrame) {
      case 'D':
        apiUrl = `${URLDOMAIN}api/transction/getTradeVolTimeday?chainid=${currentChainId}`;
        break;
      case 'W':
        apiUrl = `${URLDOMAIN}api/transction/getTradeVolTimeweek?chainid=${currentChainId}`;
        break;
      case 'M':
        apiUrl = `${URLDOMAIN}api/transction/getTradeVolTimemonth?chainid=${currentChainId}`;
        break;
      case 'Y':
        apiUrl = `${URLDOMAIN}api/transction/getTradeVolTimeyear?chainid=${currentChainId}`;
        break;
      default:
        console.error('Invalid time frame:', timeFrame);
        return;
    }
    const fetchData = async (url) => {
      if (typeof url !== 'string' || !url) {
        console.error('Invalid URL provided for fetching data.');
        return;
      }
      try {
        const response = await fetch(url);
        if (!response.ok) {
          console.error(`Failed to fetch data: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log("graphrawdata",data);
        const formattedData = data.data.map(item => ({
          x: item.timePeriod,
          y: item.totalAmountAUSD,
        }));
        setChartData(formattedData);
        console.log('Formatted data:', formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(apiUrl);
  }, [timeFrame,currentChainId]);

  function calculateAverage(data) {
    const total = data.reduce((sum, point) => sum + point.y, 0);
    return (total / data.length).toFixed(2);
  }

  const averageValue = calculateAverage(chartData);
  useEffect(() => {
    try {
      setAvgvalue(averageValue);
    } catch (error) {
      console.error("Error setting average value:", error);
    }
  }, [averageValue]);

  const handleBarHover = (event, elements) => {
    try {
      setIsBarHovered(elements.length > 0 ? true : false);
    } catch (error) {
      console.error("Error handling bar hover:", error);
      setIsBarHovered(false);
    }
  };

  useEffect(() => {
    if (chartRef.current && !chartInstance) {
      const ctx = chartRef.current.getContext('2d');
      const chart = new Chart(ctx, {
        type: 'bar',
        background: "transparent",
        data: {
          datasets: [
            {
              data: chartData, backgroundColor: '#40fcfe',
              hoverBackgroundColor: 'rgba(64, 252, 254, 0.3)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          onHover: handleBarHover,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  if (context && context.parsed) {
                    const date = new Date(context.parsed.x);
                    const formattedDate = format(date, 'PP');
                    const value = context.parsed.y;
                    setHoveredValue(value);
                    setHoveredDate(formattedDate);
                    return `Date: ${formattedDate}, Value: ${value}`;
                  }
                  return `Average Value: ${averageValue}`;
                }
              }
            },
            hoverHeight: {
              hoverHeight: 300,
            },
          },
          barThickness: 10,
          barPercentage: 0.9,
          categoryPercentage: 0.8,
          borderSkipped: false,
          borderRadius: 99,
          hoverOffset: 50,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'week',
              },
              display: true,
              grid: {
                display: false,
              },
              ticks: {
                display: true,
                autoSkip: true,
                maxRotation: 0,
                minRotation: 0,
                callback: function (value, index, values) {
                  const date = new Date(value);
                  let formatString;
                  switch (timeFrame) {
                    case 'D':
                      formatString = index === 0 ? 'MMM yyyy' : 'dd MMM';
                      break;
                    case 'W':
                      formatString = index % 2 === 0 ? 'MMM yy' : "'Week' W";
                      break;
                    case 'M':
                      const year = date.getFullYear();
                      if (date.getMonth() === 0 || (year % 4 === 0 && date.getMonth() === 1)) {
                        formatString = 'MMM yyyy';
                      } else {
                        formatString = '';
                      }
                      break;
                    case 'Y':
                      formatString = 'yyyy';
                      break;
                    default:
                      formatString = '';
                      break;
                  }
                  return format(date, formatString);
                },
              },
            },
            y: {
              beginAtZero: true,
              display: false,
              grid: {
                display: false
              }
            },
          },
        },
      });
      setChartInstance(chart);
    }
  }, [chartRef, chartInstance, chartData]);

  useEffect(() => {
    if (chartInstance) {
      let data;
      let unit;
      switch (timeFrame) {
        case 'D':
          data = chartData;
          unit = 'day';
          break;
        case 'W':
          data = chartData;
          unit = 'week';
          break;
        case 'M':
          data = chartData;
          unit = 'month';
          break;
        case 'Y':
          data = chartData;
          unit = 'year';
          break;
        default:
          break;
      }
      chartInstance.config.data.datasets[0].data = data;
      chartInstance.config.options.scales.x.time.unit = unit;
      chartInstance.update();
    }
  }, [timeFrame, chartInstance, chartData]);

  return (
    <div>
      <div style={{ width: '100%', height: '350px' }}>
        <canvas width={'100%'} ref={chartRef}></canvas>
      </div>
    </div>
  );

};

export default BarChart;