import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cardimg1 from '../Assets/images2/cardimg1.png'
import cardimg2 from '../Assets/images2/carimg2.png'
import cardimg3 from '../Assets/images2/cardimg3.png'
import cardimg4 from '../Assets/images2/cardimg4.png'
import cardimg5 from '../Assets/images2/cardimg5.png'
import cardimg6 from '../Assets/images2/cardimg6.png'
import cardimg7 from '../Assets/images2/cardimg7.png'
import cardimg8 from '../Assets/images2/cardimg8.png'
import circularimg from '../Assets/images2/circularimg.png'
import lady from '../Assets/images2/lady.png'
import twitter from '../Assets/images2/twitter.png'
import telegram from '../Assets/images2/logos_telegram.png'
import cross from '../Assets/images2/radix-icons_cross-2.svg';
import trenddx from '../Assets/images2/Trenddxlogo.png'
import menuicon from '../Assets/images2/tabler_menu-deep.png'

const MainHome = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => setMenuOpen(window.innerWidth > 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <div style={{ background: 'linear-gradient(180deg, #020202 7.28%, #03060f 18.5%, #020202 78.5%)', minHeight: '100vh' }}>
                <section className='sticky-top '>
                    <div className='_883456mh'>
                        <div className='_883456imh'>
                            <a href="/" className="fmUYaN"><img src={trenddx} className='trenddx-logo' alt='logo' /></a>
                            <div className='d-flex align-items-center justify-content-end'>
                                <nav className="Navigation__LargeWrapper-sc-v46eg4-2 bYSOwc">
                                    <a className="Navigation__StyledLink-sc-v46eg4-1 jXvTxb px-4 py-4" href="#protocol">Protocol</a>
                                    <a className="Navigation__StyledLink-sc-v46eg4-1 jXvTxb px-4 py-4" href="#keyfeatures">Key Features</a>
                                    <a className="Navigation__StyledLink-sc-v46eg4-1 jXvTxb px-4 py-4" href="#howtostart">How to get started</a>
                                </nav>
                                <Link to='/main'>
                                    <button className="launch-app-parent me-3" style={{ marginLeft: 'auto', color: '#1b1c1e', fontWeight: '650' }}>
                                        Launch App
                                    </button></Link>
                                <div className="Navigation__MobileWrapper-sc-v46eg4-4 eFGaxv">
                                    <img src={menuicon} alt='' style={{ height: '24px', width: '24px', cursor: 'pointer' }} onClick={toggleMenu} />
                                </div>
                            </div>
                        </div>
                        {menuOpen && (
                            <nav className="MobileMenu yGBCj d-flex justify-content-between p-3">
                                <div className='d-flex flex-column' style={{ gap: '10px 0px' }}>
                                    <a className="MobileMenu__Link" href="#protocol">Protocol</a>
                                    <a className="MobileMenu__Link" href="#keyfeatures">Key Features</a>
                                    <a className="MobileMenu__Link" href="#howtostart">How to get started</a>
                                </div>
                                <div className="CloseIcon" onClick={toggleMenu}>
                                    <img src={cross} style={{ height: '24px', width: '24px', cursor: 'pointer' }} alt='' />
                                </div>
                            </nav>
                        )}
                    </div>
                </section>
                <section id='protocol' style={{ paddingTop: '70px' }}>
                    <div>
                        <b className="trenddx ">
                            <span>TREND<sup className='dx'>DX</sup></span>
                            <span className='protocol'>PROTOCOL</span>
                        </b>
                    </div>
                </section>
                <section>
                    <div className='col-md-11  mx-auto position-relative d-flex flex-column justify-content-end align-items-center'>
                        <div className='px-4'>
                            <img src='https://bafybeihep3tbmchcrmraq27amngauroo3tetj7kenrawuus7duyyw2dx74.ipfs.nftstorage.link/' className='img-fluid' alt='' />
                        </div>
                        <div className='overlay-text text-center position-absolute '>
                            Implement the stable growth protocol for optimal asset development.
                        </div>
                    </div>
                    <div className='col-md-8 col-sm-12 mx-auto position-relative d-flex flex-column justify-content-end align-items-center'>
                        <div className="trenddx-is-your text-center">
                            TrendDx is your premier destination for seamless multi-chain decentralized trading.
                        </div>
                    </div>
                </section>
                <section id='keyfeatures' style={{ paddingTop: '70px' }}>
                    <div>
                        <b className="trenddx">
                            <span>TREND<sup className='dx'>DX</sup></span>
                            <span className='protocol'>KEY FEATURES</span>
                        </b>
                    </div>
                </section>
                <section>
                    <div className='container mt-5 '>
                        <div className='col-md-12 mx-auto'>
                            <div className='row mt-3'>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg1} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">Secure Trading:</b>
                                            <div className="trade-with-confidence">"Trade with confidence. Our DEX operates on blockchain technology ensuring that all transactions are secure, transparent, and immutable.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg2} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">No Third-Party Interference:</b>
                                            <div className="trade-with-confidence">"Enjoy trading without any intermediaries. Our decentralized nature means there's no middle man. You control your funds, and trades are executed directly between users."</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mx-auto'>
                            <div className='row mt-3'>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg3} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">Multi-Chain Support:</b>
                                            <div className="trade-with-confidence">Trade across multiple blockchain networks with ease. Whether you're interested in Ethereum, Binance Smart Chain, Polygon, Base or beyond, InterChain Exchange has you covered.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg4} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">Supply Liquidity:</b>
                                            <div className="trade-with-confidence">Earn 0.25% fee on trades proportional to your share of the pool.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mx-auto'>
                            <div className='row mt-3'>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg5} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">Token Burn Mechanism:</b>
                                            <div className="trade-with-confidence">Our platform incorporates an additional function of an automatic token burn process, wherein a portion of each sold token is sent to the Genesis Address, reducing the token supply and enhancing overall stability.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg6} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">Level One Referral System:</b>
                                            <div className="trade-with-confidence">Introduce others to TrendSwap and earn referral commissions. Share the benefits of multi-chain trading with your network and earn rewards for every successful referral.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12  mx-auto'>
                            <div className='row mt-3 '>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg7} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">User-Friendly Interface:</b>
                                            <div className="trade-with-confidence">Our intuitive interface makes trading on multiple chains simple and straightforward, even for beginners.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="parent">
                                        <img className="icon" alt="" src={cardimg8} />
                                        <div className="secure-trading-parent">
                                            <b className="secure-trading">Low Fees:</b>
                                            <div className="trade-with-confidence">"Maximize your returns with our low transaction fees. We minimize trading costs to help you get the most out of your investments."</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='howtostart' style={{ paddingTop: '70px' }}>
                    <div>
                        <b className="trenddx">
                            <span>TREND<sup className='dx'>DX</sup></span>
                            <span className='protocol'>HOW TO GET STARTED</span>
                        </b>
                    </div>
                </section>
                <section>
                    <div className='container mt-5 '>
                        <div className='col-md-12 mx-auto'>
                            <div className='row mt-3 align-items-center'>
                                <div className='col-md-6'>
                                    <div className="parent" style={{ justifyContent: 'center', }}>
                                        <img src={circularimg} className='img-fluid' style={{ borderRadius: '999px' }} alt="" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="_88306012">
                                        <div className="secure-trading-parent ">
                                            <b className="secure-trading" style={{ fontSize: '20px' }}>Step 1: Connect Your Wallet:</b>
                                            <div className="trade-with-confidence" style={{ fontSize: '16px' }}> "Securely connect your blockchain wallet to our platform. We support a variety of popular wallets for your convenience."</div>
                                        </div>
                                        <div className="secure-trading-parent ">
                                            <b className="secure-trading" style={{ fontSize: '20px' }}>Step 2: Deposit Your Assets:</b>
                                            <div className="trade-with-confidence" style={{ fontSize: '16px' }}> "Transfer the cryptocurrencies you want to trade into your connected wallet. Our platform supports multiple assets for trading."</div>
                                        </div>
                                        <div className="secure-trading-parent ">
                                            <b className="secure-trading" style={{ fontSize: '20px' }}>Step 3: Start Trading: </b>
                                            <div className="trade-with-confidence" style={{ fontSize: '16px' }}>"Navigate our intuitive trading interface to buy or sell cryptocurrencies. Enjoy real-time trading with direct market access."</div>
                                        </div>
                                        <div className="secure-trading-parent ">
                                            <b className="secure-trading" style={{ fontSize: '20px' }}>Step 4: Withdraw Your Funds:</b>
                                            <div className="trade-with-confidence" style={{ fontSize: '16px' }}> "Transfer your assets back to your personal wallet or continue trading. Your funds, your control."</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container mt-5'>
                        <div className='col-md-12 mx-auto'>
                            <div className='row mt-3'>
                                <div className='col-md-8 '>
                                    <span className='start-trading-today '>Start Trading Today</span>
                                    <div className='experience-the-power'>Experience the power of multi-chain trading with TrendDx. Connect to our platform and start trading across different blockchain networks with ease.</div>
                                    <div className='d-flex mt-5 mb-5 social-icon' style={{ gap: '10px 10px' }}>
                                        <a href='https://twitter.com/TTAvatars' target='_blank' className='_883060122'><button className='d-flex align-items-center justify-content-center'><img src={twitter} style={{ height: '20px', width: '20px' }} alt='' /><span className='ms-1'>Twitter</span></button></a>
                                        <a href='https://t.me/+Ssi1uUZQz6sxNGRl' target='_blank' className='_883060122'><button className='d-flex align-items-center justify-content-center'><img src={telegram} style={{ height: '20px', width: '20px' }} alt='' /><span className='ms-1'>Telegram</span></button></a>
                                        <a href='https://t.me/trendavatars' target='_blank' className='_883060122'><button className='d-flex align-items-center justify-content-center'><img src={telegram} style={{ height: '20px', width: '20px' }} alt='' /><span className='ms-1'> Channel</span></button></a>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="_88306012">
                                        <div style={{ height: '200px', width: '230px' }}>
                                            <img className='img-fluid' src={lady} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <div className='container mt-5'>
                        <div className='col-md-12 mx-auto'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div class="rectangle-div mx-2"><img src={trenddx} className='trenddx-logo' alt='logo' /></div>
                                    <div className='mt-2 p-2' style={{ fontSize: '12px', color: '#C4C4C4' }}>Disclaimer: TrendDx is committed to providing a secure and transparent trading environment. However, please note that cryptocurrency trading involves risks, and users should conduct their own research and exercise caution when trading.</div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='d-flex flex-column justify-content-end'>
                                        <div className='d-flex justify-content-end '>
                                            <a class="Navigation__StyledLink-sc-v46eg4-1 jXvTxb px-4 py-4" style={{ color: '#C4C4C4', fontSize: '14px' }} href="#protocol">Protocol</a>
                                            <a class="Navigation__StyledLink-sc-v46eg4-1 jXvTxb px-4 py-4" style={{ color: '#C4C4C4', fontSize: '14px' }} href="#keyfeatures">Key Features</a>
                                            <a class="Navigation__StyledLink-sc-v46eg4-1 jXvTxb px-4 py-4" style={{ color: '#C4C4C4', fontSize: '14px' }} href="#howtostart">How to get started</a>
                                        </div>
                                        <div className='d-flex social-icon-footer  py-4 px-3 gap-3'>
                                            <a href='https://twitter.com/TTAvatars' target='_blank'><img src={twitter} style={{ height: '24px', width: '24px' }} alt='' /></a>
                                            <a href='https://t.me/+Ssi1uUZQz6sxNGRl' target='_blank'><img src={telegram} style={{ height: '24px', width: '24px' }} alt='' /></a>
                                            {/* <a href='https://t.me/trendavatars' target='_blank'><img src={telegram} style={{ height: '24px', width: '24px' }} alt='' /></a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex mb-3 ' style={{ gap: '0px 50px', justifyContent: 'center' }}>
                                    <Link to="#terms" style={{ color: '#C4C4C4', fontSize: '12px', }}>Terms & Conditions</Link>
                                    <Link to="#privacy-policy" style={{ color: '#C4C4C4', fontSize: '12px' }}>Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );

};

export default MainHome;