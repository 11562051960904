import Routing from "./Router/Routing";

function App() {

  return (
    <>
      <div className="App app-container">
        <Routing />
      </div>
    </>
  );
  
}

export default App;