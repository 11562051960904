import React, { useState } from 'react';
import LiquidityTable from './LiquidityTable';
import backwardarrow from '../Assets/images2/backwardarrow.png';
import forwardarrow from '../Assets/images2/forwardarrow.png';
import LiquidityParent from '../LiquidtyContent/LiquidityParent';
import BarChart from '../Dropdown/BarChart';

const LiquidityHub = () => {

  const [timeFrameClass, setTimeFrameClass] = useState({
    D: 'd-wrapper',
    W: 'w-wrapper',
    p: 'd-wrapper',
    q: 'w-wrapper',
    r: 'w-wrapper',
    s: 'w-wrapper',
    t: 'w-wrapper',
  });

  const handleTimeFrameClick = (timeFrame) => {
    const updatedClass = Object.fromEntries(
      Object.entries(timeFrameClass).map(([key, value]) => [key, key === timeFrame ? 'd-wrapper' : 'w-wrapper'])
    );
    setTimeFrameClass(updatedClass);
  };

  return (
    <>
      <div className='container mt-3' style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'start', gap: '0px 40px' }}>
        <div>
          <LiquidityParent time='Since 2023-09-18' />
        </div>
        <div>
          <LiquidityParent time='Last 30 days' />
        </div>
        <div>
          <LiquidityParent time='Last 24 hours' />
        </div>
      </div>
      <div className='container  glassmorphism  mt-5' style={{ borderRadius: '16px' }}>
        <div className='d-flex align-items-start justify-content-between  px-3 '>
          <div>
            <p className='m-0 p-0' style={{ fontSize: '16px', color: '#C7CAD9' }}>Liquidity hub</p>
          </div>
          <div className="parent-frame ">
            <button className={timeFrameClass.p} onClick={() => handleTimeFrameClick('p')}>
              1M
            </button>
            <button className={timeFrameClass.q} onClick={() => handleTimeFrameClick('q')}>
              3M
            </button>
            <button className={timeFrameClass.r} onClick={() => handleTimeFrameClick('r')}>
              5M
            </button>
            <button className={timeFrameClass.s} onClick={() => handleTimeFrameClick('s')}>
              1Y
            </button>
            <button className={timeFrameClass.t} onClick={() => handleTimeFrameClick('t')}>
              All
            </button>
          </div>
        </div>
        <div className='px-3'>
          <BarChart />
        </div>
      </div>
      <div className='container mt-5 px-0' style={{ backgroundColor: '#01336080', borderRadius: '8px' }}>
        <div className='d-flex align-items-start justify-content-between mt-3  mx-4 p-2 px-3 '>
          <p className='m-0 p-0' style={{ fontSize: '16px', fontWeight: '600', color: '#C7CAD9' }}>LH Swaps</p>
          <div className="arrowparent ">
            <img className="arrow me-3" alt="" src={backwardarrow} style={{ height: '16px', width: '16px' }} />
            <img className="arrow" alt="" src={forwardarrow} style={{ height: '16px', width: '16px' }} />
          </div>
        </div>
        <LiquidityTable />
      </div>
    </>
  )

}

export default LiquidityHub;