import React, { useCallback, useContext, useState } from 'react';
import OverviewTable1 from './OverviewTable1';
import OverviewTable2 from './OverviewTable2';
import forwardarrow from '../Assets/images2/iconamoon_arrow-up-2-thin (3).png';
import Context from '../Context/Context';
import AnlyaticsBarchart2 from '../Dropdown/AnlyaticsBarchart2';
import AnalyticsLineChart1 from '../Dropdown/AnalyticsLineChart1';

const Overview = () => {

    const { setActiveSection, setActiveTab, timeFrame, setTimeFrame, hoveredValue, avgvalue, isBarHovered } = useContext(Context);

    const handleTimeFrameClick = (timeFrame) => {
        setTimeFrame(timeFrame);
    };

    const onFrameContainerClick = useCallback((section, tab) => {
        setActiveSection(section);
        setActiveTab(tab);
    }, [setActiveSection, setActiveTab]);

    const [timeFrameClass, setTimeFrameClass] = useState({
        p: 'jHHQTI',
        q: 'w-wrapper',
        r: 'w-wrapper',
        s: 'w-wrapper',
        t: 'w-wrapper',
    });
    const [timeFrameClass1, setTimeFrameClass1] = useState({
        p: 'jHHQTI',
        q: 'w-wrapper',
        r: 'w-wrapper',
        s: 'w-wrapper',
        t: 'w-wrapper',
    });

    const [volumeClass, setVolumeClass] = useState({
        p: 'jHHQTI',
        q: 'w-wrapper',
    });

    const [secondClass, setSecondClass] = useState({
        p: 'jHHQTI',
        q: 'w-wrapper',
        r: 'w-wrapper',
        s: 'w-wrapper',
        t: 'w-wrapper',
    });

    const handleButtonClick = (setter, key) => {
        setter((prevClass) => ({
            ...Object.keys(prevClass).reduce((acc, cur) => ({
                ...acc,
                [cur]: cur === key ? 'jHHQTI' : 'w-wrapper',
            }), {}),
        }));
    };

    const handleButtonClick1 = (setter, key) => {
        setter((prevClass) => ({
            ...Object.keys(prevClass).reduce((acc, cur) => ({
                ...acc,
                [cur]: cur === key ? 'jHHQTI' : 'w-wrapper',
            }), {}),
        }));
    };

    const TimeFrameButton = ({ key, className, onClick }) => (
        <button key={key} className={className} onClick={() => onClick(key)}>
            {key === 'p' ? '1M' : key === 'q' ? '3M' : key === 'r' ? '5M' : key === 's' ? '1Y' : 'All'}
        </button>
    );

    return (
        <div>
            <div className="container d-flex flex-md-row flex-column px-0 mt-2" style={{ gap: '0px 10px' }}>
                <div className="col-md-6 col-12">
                    <div className="bfQFKpc vwKsb eAjRWn">
                        <div className="fzzMop">
                            <div className="xsFBJ indocQ gvVAeR col-md-6 col-sm-12">
                                <div className="d-flex align-items-start justify-content-between">
                                    <div>
                                        <p className="m-0 p-0" style={{ fontSize: '16px', color: '#C7CAD9' }}>
                                            Liquidity
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <b className="m">$104.8M</b>
                                            <div className="parent1 ms-2 px-2">
                                                <b className="b">1.313%</b>
                                            </div>
                                        </div>
                                        <p className="m-0 p-0" style={{ fontSize: '16px', color: '#C7CAD9' }}>
                                            Feb 28 2024
                                        </p>
                                    </div>
                                    <div className="parent-frame">
                                        {['p', 'q', 'r', 's', 't'].map((key) => (
                                            <button key={key} className={timeFrameClass[key]} onClick={() => handleButtonClick(setTimeFrameClass, key)}>
                                                {key === 'p' ? '1M' : key === 'q' ? '3M' : key === 'r' ? '5M' : key === 's' ? '1Y' : 'All'}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ height: "100%", width: "100%" }}>
                                    <AnalyticsLineChart1 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="bfQFKpc vwKsb eAjRWn">
                        <div className="fzzMop">
                            <div className="xsFBJ indocQ gvVAeR col-md-6 col-sm-12">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="mb-0" style={{ fontSize: '16px', color: '#C7CAD9' }}>
                                        Volume(24hr)
                                    </p>
                                    <div className="d-flex mb-1" style={{ gap: '0px 5px' }}>
                                        <button className={timeFrame === 'D' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('D')}>
                                            D
                                        </button>
                                        <button className={timeFrame === 'W' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('W')}>
                                            W
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between m-0">
                                    <div className="d-flex align-items-center m-0 p-0">
                                        <b className="m">${isBarHovered ? hoveredValue : avgvalue}</b>
                                        <div className="parent2 ms-2 px-2">
                                            <b className="b">1.313%</b>
                                        </div>
                                    </div>
                                    <div className="parent-frame">
                                        <button className={timeFrame === '1M' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('1M')}>
                                            1M
                                        </button>
                                        <button className={timeFrame === '3M' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('3M')}>
                                            3M
                                        </button>
                                        <button className={timeFrame === '6M' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('6M')}>
                                            6M
                                        </button>
                                        <button className={timeFrame === 'Y' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('Y')}>
                                            1Y
                                        </button>
                                        <button className={timeFrame === 'A' ? 'jHHQTI' : 'w-wrapper'} onClick={() => handleTimeFrameClick('A')}>
                                            All
                                        </button>
                                    </div>
                                </div>
                                <div style={{ height: "350px", width: "100%" }}>
                                    <AnlyaticsBarchart2 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container d-flex mt-3 align-items-center justify-content-start glassmorphism" style={{ height: '46px', borderRadius: '15px' }}>
                <div className="d-flex ms-3" style={{ gap: '0px 40px' }}>
                    <div className="d-flex align-items-center" style={{ gap: '0px 8px' }}>
                        <span style={{ fontSize: '16px', color: '#C7CAD9' }}>
                            24H Volume: $118,403,423.497
                        </span>
                        <div className="topMoverText">
                            <b className="b">+0.962%</b>
                        </div>
                    </div>
                    <div className="d-flex align-items-center" style={{ gap: '0px 8px' }}>
                        <span style={{ fontSize: '16px', color: '#C7CAD9' }}>
                            24h Fees: $78,608.634
                        </span>
                        <div className="topMoverText" style={{ background: "rgba(214, 95, 82, 0.25)" }}>
                            <b className="b" style={{ color: "rgba(214, 95, 82, 1)" }}>-9.894%</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container d-flex align-items-center justify-content-between mt-4 px-0">
                <p className="m-0 top-tokens">Top Tokens</p>
                <p className="m-0 see-all pairs-container" style={{ cursor: 'pointer' }} onClick={() => onFrameContainerClick('token', 'token')}>
                    See All
                    <span className="pagination-icon">
                        <img src={forwardarrow} style={{ height: '16px', width: '16px' }} className="ms-2" alt="Pagination" />
                    </span>
                </p>
            </div>
            <OverviewTable1 />
            <div className="container d-flex align-items-center justify-content-between mt-4 px-0">
                <p className="m-0 top-tokens">Top Pairs</p>
                <p className="m-0 see-all pairs-container" style={{ cursor: 'pointer' }} onClick={() => onFrameContainerClick('pairs', 'pairs')}>
                    See All
                    <span className="pagination-icon">
                        <img src={forwardarrow} style={{ height: '16px', width: '16px' }} className="ms-2" alt="Pagination" />
                    </span>
                </p>
            </div>
            <OverviewTable2 />
        </div>
    );

};

export default Overview;