import React from 'react'
import { Link } from 'react-router-dom'

const LiquidityReward = () => {

    return (
        <>
            <div className='container' style={{ margin: '120px auto' }}>
                <div className='col-md-7 mx-auto   p-2' style={{ background: 'linear-gradient(180deg, #032E50 0%, #101525 100%), linear-gradient(180deg, rgba(217, 217, 217, 0.075) 0%, rgba(217, 217, 217, 0.075) 100%)', borderRadius: 8 }}>
                    <p className='liquidity-provider-rewards m-0'>Liquidity provider rewards</p>
                    <p className='liquidity-providers-earn'>Liquidity providers earn a 0.25% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.</p>
                </div>
                <div className='col-md-7 mx-auto mt-4 d-flex justify-content-between px-2'>
                    <div>
                        <p className='your-liquidity'>Your Liquidity</p>
                    </div>
                    <div className='d-flex' style={{ gap: '0px 20px' }}>
                    <button className='your-liquidity' style={{border:"1px solid white",borderRadius:"10px",padding:"0 20px"}}>
                        Create a pair
                    </button>
                        <Link to='/pool=add Liquidty'><button type='button' className="add-liquidity">Add Liquidity</button></Link>
                    </div>
                </div>
                <div className='col-md-7 mx-auto mt-4 px-2'>
                    <div className="no-liquidity-found-parent">
                        <p className="no-liquidity-found">No liquidity found</p>
                    </div>
                </div>
                <div className='col-md-7 mx-auto mt-3 px-2 d-flex justify-content-center'>
                    <div className="dont-see-a">Don't see a pool you joined?</div>
                    <Link to='/pool=import Pool'>
                        <p className="import-it ms-2">Import it.</p>
                    </Link>
                </div>
            </div>
        </>
    )

}

export default LiquidityReward;