import React, { useState, useEffect, useContext } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { getTokenLiveInfoDetails } from '../ContractAction/TrendswapAction';
import Context from '../Context/Context';

let cachedTokenTableData = [];

const TokenTable = ({ filteredData }) => {

    const { setSearchTokentabledata } = useContext(Context);
    const [tokenData, setTokenData] = useState(cachedTokenTableData);
    const [pageSize, setPageSize] = useState(5);

    const calculate1HourDifference = (coin) => {
        try {
            const sparkline = coin.sparkline;
            if (!Array.isArray(sparkline) || sparkline.length < 2) {
                console.error("Invalid sparkline data");
            }
            const lastPrice = parseFloat(sparkline[sparkline.length - 2]);
            const secondLastPrice = parseFloat(coin.price);
            if (isNaN(lastPrice) || isNaN(secondLastPrice)) {
                console.error("Invalid price data");
            }
            const absoluteDifference = lastPrice - secondLastPrice;
            const averagePrice = (lastPrice + secondLastPrice) / 2;
            if (averagePrice === 0) {
                console.error("Average price is zero, cannot calculate percentage difference");
            }
            const percentageDifference = ((absoluteDifference / averagePrice) * 100).toFixed(2);
            console.log("calculate1HourDifference:", percentageDifference);
            return percentageDifference;
        } catch (error) {
            console.error("Error calculating 1 hour difference:", error.message);
            return null;
        }
    };

    const formatValue = (value) => {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            console.error("Invalid input: Not a number");
            return "$0.00";
        }
        const absValue = Math.abs(value);
        if (absValue >= 1e12) {
            return `$${(value / 1e12).toFixed(2)}T`;
        } else if (absValue >= 1e9) {
            return `$${(value / 1e9).toFixed(2)}B`;
        } else if (absValue >= 1e6) {
            return `$${(value / 1e6).toFixed(2)}M`;
        } else if (absValue >= 1e3) {
            return `$${(value / 1e3).toFixed(2)}K`;
        } else {
            return `$${value}`;
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: () => <div style={{ fontWeight: '500', minWidth: '100px' }}>Rank</div>,
                accessor: 'rank',
            },
            {
                Header: () => <div style={{ paddingLeft: "20px", textAlign: "left", fontWeight: '500', minWidth: '100px' }}>Token Name</div>,
                accessor: 'name',
                Cell: ({ row }) => (
                    <div style={{ textAlign: 'left', paddingLeft: "20px", minWidth: '250px' }}>
                        <img src={row.original.iconUrl} style={{ borderRadius: "50%" }} className="tokenimg me-2" alt="" /> {row.original.name} <span style={{ color: "#a9a9a9" }}>{row.original.symbol}</span>
                    </div>
                ),
            },
            {
                Header: () => <div style={{ textAlign: 'right', fontWeight: '500', minWidth: '100px' }}><span>Price</span></div>,
                accessor: 'price',
                Cell: ({ value }) => <div style={{ textAlign: "right" }}>${Number(value).toFixed(2)}</div>,
            },
            {
                Header: () => <div style={{ textAlign: "right", fontWeight: '500', minWidth: '100px' }}>1 Hour</div>,
                accessor: 'hourChange',
                Cell: ({ value }) => (
                    <div style={{ textAlign: "right" }}>
                        {value > 0 ? (
                            <span style={{ color: 'rgb(64, 182, 107)' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" aria-label="up" className="tokenimg Delta__StyledUpArrow-sc-7d13dc9d-0 iJXlkm">
                                    <path d="M13.3021 7.7547L17.6821 14.2475C18.4182 15.3388 17.7942 17 16.6482 17L7.3518 17C6.2058 17 5.5818 15.3376 6.3179 14.2475L10.6979 7.7547C11.377 6.7484 12.623 6.7484 13.3021 7.7547Z" fill="rgb(64, 182, 107)"></path>
                                </svg>{value}%
                            </span>
                        ) : (
                            <span style={{ color: 'rgb(255, 95, 82)' }}>
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="down" className="Delta__StyledDownArrow-sc-7d13dc9d-1 flhNlw">
                                    <path d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z" fill="rgb(255, 95, 82)"></path>
                                </svg> {value}%
                            </span>
                        )}
                    </div>
                ),
            },
            {
                Header: () => <div style={{ textAlign: "right", fontWeight: '500', minWidth: '100px' }}>1 Day</div>,
                accessor: 'change',
                Cell: ({ value }) => (
                    <div style={{ textAlign: "right" }}>
                        {value > 0 ? (
                            <span style={{ color: 'rgb(64, 182, 107)' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" aria-label="up" className=" tokenimg Delta__StyledUpArrow-sc-7d13dc9d-0 iJXlkm">
                                    <path d="M13.3021 7.7547L17.6821 14.2475C18.4182 15.3388 17.7942 17 16.6482 17L7.3518 17C6.2058 17 5.5818 15.3376 6.3179 14.2475L10.6979 7.7547C11.377 6.7484 12.623 6.7484 13.3021 7.7547Z" fill="rgb(64, 182, 107)"></path>
                                </svg>{value}%
                            </span>
                        ) : (
                            <span style={{ color: 'rgb(255, 95, 82)' }}>
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="down" className="Delta__StyledDownArrow-sc-7d13dc9d-1 flhNlw">
                                    <path d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z" fill="rgb(255, 95, 82)"></path>
                                </svg> {value}%
                            </span>
                        )}
                    </div>
                ),
            },
            {
                Header: () => <div style={{ textAlign: "right", fontWeight: '500', minWidth: '100px' }}>FDV</div>,
                accessor: 'marketCap',
                Cell: ({ value }) => <div style={{ textAlign: "right" }}>{formatValue(value)}</div>,
            },
            {
                Header: () => <div style={{ textAlign: "right", fontWeight: '500', minWidth: '100px' }}>Volume</div>,
                accessor: '24hVolume',
                Cell: ({ value }) => <div style={{ textAlign: "right" }}>{formatValue(value)}</div>,
            },
            {
                Header: () => <div style={{ textAlign: "right", fontWeight: '500', minWidth: '100px' }}>Graph</div>,
                accessor: 'graphImage',
                Cell: ({ value }) => <img src={value} style={{ height: '24px', width: '124px' }} alt="Graph" />,
            },
        ],
        []
    );

    const {
        getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, page,
        pageCount, state: { pageIndex }, nextPage, previousPage, setPageSize: setTablePageSize,
    } = useTable(
        {
            columns,
            data: filteredData.length > 0 ? filteredData : tokenData,
            initialState: { pageIndex: 0, pageSize },
        },
        useSortBy,
        usePagination
    );
    console.log(filteredData, "filteredDatatoken");

    useEffect(() => {
        try {
            setSearchTokentabledata(tokenData);
        } catch (error) {
            console.error("Error setting search token table data:", error);
        }
    }, [tokenData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const TokenLiveInfoDetails = await getTokenLiveInfoDetails();
                const updatedTokenData = TokenLiveInfoDetails.data.coins.map(coin => ({
                    ...coin,
                    hourChange: calculate1HourDifference(coin),
                }));
                try {
                    cachedTokenTableData = updatedTokenData;
                    console.log("cachedTokenTableData:", updatedTokenData);
                } catch (error) {
                    console.error("Error caching token table data:", error);
                }
                try {
                    setTokenData(updatedTokenData);
                    console.log("tokenData:", updatedTokenData);
                } catch (error) {
                    console.error("Error setting token data:", error);
                }
            } catch (error) {
                console.error("Error fetching token live info details:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        try {
            setTablePageSize(pageSize);
        } catch (error) {
            console.error("Error setting table page size:", error);
        }
    }, [setTablePageSize, pageSize]);

    const totalRows = filteredData.length > 0 ? filteredData.length : tokenData.length;
    const startRecordIndex = pageIndex * pageSize + 1;
    const endRecordIndex = Math.min((pageIndex + 1) * pageSize, totalRows);

    return (
        <div className='container mt-2'>
            <div className='table-responsive' style={{ borderRadius: '15px' }}>
                <Table {...getTableProps()} className='table '>
                    <thead className='tablestruct' style={{ color: 'rgba(155, 151, 151, 1)' }}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`text-center ${column.isSorted ? 'sorted-column' : ''}`}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {column.render('Header')}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="asc" className="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                                                        <line x1="12" y1="19" x2="12" y2="5"></line>
                                                        <polyline points="5 12 12 5 19 12"></polyline>
                                                    </svg>) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="desc" className="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                                        <polyline points="19 12 12 19 5 12"></polyline>
                                                    </svg>
                                                )
                                            ) : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} style={{ background: 'linear-gradient(180deg, #032E50 0%, #101525 100%), linear-gradient(180deg, rgba(217, 217, 217, 0.075) 0%, rgba(217, 217, 217, 0.075) 100%)' }}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className='glassmorphism text-center table-row' style={{ color: "white" }}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <div className='d-flex justify-content-end align-items-center gap-3'>
                <div className="custom-dropdown d-flex align-items-center justify-content-center">
                    Records per page:
                    <div className="dropdown-toggle" onClick={() => document.getElementById("dropdown-menu").classList.toggle("show")}>
                        {pageSize}
                    </div>
                    <div id="dropdown-menu" className="dropdown-menu">
                        <div className="dropdown-item" onClick={() => { setPageSize(3); document.getElementById("dropdown-menu").classList.remove("show"); }}>3</div>
                        <div className="dropdown-item" onClick={() => { setPageSize(5); document.getElementById("dropdown-menu").classList.remove("show"); }}>5</div>
                        <div className="dropdown-item" onClick={() => { setPageSize(10); document.getElementById("dropdown-menu").classList.remove("show"); }}>10</div>
                    </div>
                </div>
                <div className='pageCount'>
                    {startRecordIndex}-{endRecordIndex} of {totalRows}
                </div>
                <Pagination style={{ marginBottom: "0px" }}>
                    <Pagination.Prev onClick={previousPage} disabled={pageIndex === 0}>
                        <span aria-hidden="true">&laquo;</span>
                    </Pagination.Prev>
                    <Pagination.Next onClick={nextPage} disabled={pageIndex === pageCount - 1}>
                        <span aria-hidden="true">&raquo;</span>
                    </Pagination.Next>
                </Pagination>
            </div>
        </div>
    );

};

export default TokenTable;