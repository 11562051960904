import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { getAllStoredTransactions } from "../ContractAction/TrendswapAction";

let cachedTokenTXData = [];

const Pooltable = () => {

  const [tokenData, setTokenData] = useState(cachedTokenTXData);
  const [pageSize, setPageSize] = useState(5);

  const formatTimeDifference = (value) => {
    const dateValue = new Date(value);
    if (isNaN(dateValue.getTime())) {
      console.error("Invalid input: Not a valid date");
      return "- Invalid date";
    }
    const differenceInSeconds = Math.floor((new Date() - dateValue) / 1000);
    const intervals = [
      { label: 'd', duration: 86400 },
      { label: 'h', duration: 3600 },
      { label: 'm', duration: 60 },
      { label: 'sec', duration: 1 }
    ];
    for (const interval of intervals) {
      if (differenceInSeconds >= interval.duration) {
        const count = Math.floor(differenceInSeconds / interval.duration);
        return `- ${count} ${interval.label}`;
      }
    }
    return '- Just now';
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (<div style={{ fontWeight: '500' }}>Time</div>),
        accessor: 'createdAt',
        Cell: ({ value }) => formatTimeDifference(value),
      },
      {
        Header: () => (
          <div style={{ paddingLeft: "20px", textAlign: "left", fontWeight: '500', }}>Type</div>
        ),
        accessor: 'swaptokenAfortokenB',
        Cell: ({ value }) => <div style={{ textAlign: 'left', paddingLeft: "20px", minWidth: '180px' }}>{value}</div>
      }
      ,
      {
        Header: () => (<div style={{ textAlign: "center", fontWeight: '500', }}>USD</div>),
        accessor: 'amountAUSD',
        Cell: ({ value }) => <div style={{ textAlign: "start" }}>{Number(value).toFixed(2)}</div>
      },
      {
        Header: () => (
          <div style={{ textAlign: "center", fontWeight: '500', minWidth: '180px' }}>Token amount (Input)</div>
        ),
        accessor: 'amounttokenA',
        Cell: ({ value }) => <div style={{ paddingRight: "50px", textAlign: "center" }}>{value}</div>
      }
      ,
      {
        Header: () => (<div style={{ textAlign: "center", fontWeight: '500', minWidth: '180px' }}>Token amount (Output)</div>),
        accessor: 'amounttokenB',
        Cell: ({ value }) => <div style={{ paddingRight: "50px", textAlign: "center" }}>{value}</div>
      },
      {
        Header: () => (<div style={{ textAlign: "center", paddingLeft: "20px", fontWeight: '500', }}>Wallet</div>),
        accessor: 'userwallet',
        Cell: ({ value }) => <div style={{ textAlign: "center", fontWeight: '500' }}>{value.substring(0, 6)}...${value.substring(value.length - 4)}</div>,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page,
    pageCount, state: { pageIndex }, nextPage, previousPage, setPageSize: setTablePageSize,
  } = useTable({ columns, data: tokenData, initialState: { pageIndex: 0, pageSize } }, useSortBy, usePagination);

  useEffect(() => {
    const fetchAllStoredTransactions = async () => {
      try {
        const transactions = await getAllStoredTransactions();
        const sortedTransactions = transactions.map(transaction => ({
          ...transaction,
          diffInSeconds: Math.floor((new Date() - new Date(transaction.createdAt)) / 1000),
        })).sort((a, b) => a.diffInSeconds - b.diffInSeconds);
        try {
          cachedTokenTXData = sortedTransactions;
          console.log("cachedTokenTXData:", sortedTransactions);
        } catch (error) {
          console.error("Error caching transactions:", error);
        }
        try {
          setTokenData(sortedTransactions);
          console.log("setTokenData_allstoredtxs:", sortedTransactions);
        } catch (error) {
          console.error("Error setting token data:", error);
        }
      } catch (error) {
        console.error("Error fetching stored transactions:", error);
      }
    };
    fetchAllStoredTransactions();
  }, []);

  useEffect(() => {
    try {
      setTablePageSize(pageSize);
    } catch (error) {
      console.error("Error setting table page size:", error);
    }
  }, [setTablePageSize, pageSize]);

  const totalRows = tokenData.length;
  const startRecordIndex = pageIndex * pageSize + 1;
  const endRecordIndex = Math.min((pageIndex + 1) * pageSize, totalRows);

  return (
    <div className='container mt-2'>
      <div className='table-responsive' style={{ borderRadius: '15px' }}>
        <Table {...getTableProps()} className='table'>
          <thead className='tablestruct' style={{ color: 'rgba(155, 151, 151, 1)' }}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} >
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`text-center ${column.isSorted ? 'sorted-column' : ''}`}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="asc" class="styled__HeaderArrow-sc-eb013908-17 ldxGag"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>) : (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="desc" class="styled__HeaderArrow-sc-eb013908-17 ldxGag"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ background: 'linear-gradient(180deg, #032E50 0%, #101525 100%), linear-gradient(180deg, rgba(217, 217, 217, 0.075) 0%, rgba(217, 217, 217, 0.075) 100%)' }}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className=' text-center table-row' style={{ color: "white" }} key={row.id}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {tokenData.length > 0 && <div className='d-flex justify-content-end align-items-center gap-3'>
        <div className="custom-dropdown d-flex align-items-center justify-content-center">
          Records per page:
          <div className="dropdown-toggle" onClick={() => document.getElementById("dropdown-menu").classList.toggle("show")}>
            {pageSize}
          </div>
          <div id="dropdown-menu" className="dropdown-menu">
            <div className="dropdown-item" onClick={() => { setPageSize(3); document.getElementById("dropdown-menu").classList.remove("show"); }}>3</div>
            <div className="dropdown-item" onClick={() => { setPageSize(5); document.getElementById("dropdown-menu").classList.remove("show"); }}>5</div>
            <div className="dropdown-item" onClick={() => { setPageSize(10); document.getElementById("dropdown-menu").classList.remove("show"); }}>10</div>
          </div>
        </div>
        <div className='pageCount'>
          {startRecordIndex}-{endRecordIndex} of {totalRows}
        </div>
        <Pagination style={{ marginBottom: "0px", backgroundColor: "#f0f0f0" }}>
          <Pagination.Prev onClick={previousPage} disabled={pageIndex === 0}>
            <span aria-hidden="true">&laquo;</span>
          </Pagination.Prev>
          <Pagination.Next onClick={nextPage} disabled={pageIndex === pageCount - 1}>
            <span aria-hidden="true">&raquo;</span>
          </Pagination.Next>
        </Pagination>
      </div>
      }
    </div>
  );

};

export default Pooltable;